import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import imageurl from "../common/images";

const Industries =()=>{
      return(
            <Container className="industry-list">
                        <div className="ser_vi text-center mb-3">
                              <h5 className="fw-bold"><span>Industries</span> We’ve Worked With</h5>
                        </div>
                        <h5 className="text-center text-muted">ConvrtX has worked with 1,000s of Startups from Pre-Seed to Series C.</h5>

                        <Row className="industry-container col-xl-8 mx-auto justify-content-center">
                              <Col lg={3} md={4} xs={6} className="text-center">
                                    <img src={imageurl.finTech} className="img-fluid" alt="Fin tech" />
                                    <h5>FinTech</h5>
                              </Col>
                              <Col lg={3} md={4} xs={6}  className="text-center">
                                    <img src={imageurl.socialNetwork} className="img-fluid" alt="Fin tech" />
                                    <h5>Social Networking</h5>
                              </Col>
                              <Col lg={3} md={4} xs={6} className="text-center">
                                    <img src={imageurl.bigData} className="img-fluid" alt="Fin tech" />
                                    <h5>AI/Big Data</h5>
                              </Col>
                              <Col lg={3} md={4} xs={6} className="text-center">
                                    <img src={imageurl.web3} className="img-fluid" alt="Fin tech" />
                                    <h5>Web 3.0</h5>
                              </Col>
                              <Col lg={3} md={4} xs={6} className="text-center">
                                    <img src={imageurl.sass} className="img-fluid" alt="Fin tech" />
                                    <h5>SaaS</h5>
                              </Col>
                              <Col lg={3} md={4} xs={6} className="text-center">
                                    <img src={imageurl.healthTech} className="img-fluid" alt="Fin tech" />
                                    <h5>Health Tech</h5>
                              </Col>
                              <Col lg={3} md={4} xs={6} className="text-center">
                                    <img src={imageurl.b2b} className="img-fluid" alt="Fin tech" />
                                    <h5>B2B</h5>
                              </Col>
                        </Row>
                  </Container>
      )
}

export default Industries;