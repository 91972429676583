
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getNewsPr } from "../../store/actions/commonAction";
import { newsletter } from "../../store/actions/pitchDeckAction";
import imageurl from "../common/images";
import { Features } from "../Landingpage/Features";
import { Footer } from "../Landingpage/Footer";
import { Header } from "../Landingpage/Header";
import { MobileHeader } from "../Landingpage/Mobile/MobileHeader";
import SiteLoader from "../SiteLoader/SiteLoader";

const NewsPr = (props) => {
      const [key, setKey] = useState('All');
      const loadNews = props.getNewsPrList;
      const loading = props.getNews.loading;
      useEffect(() => {
            loadNews();
      }, [])
      const newsList = props.getNews.newsPr
      const [email, setEmail] = useState("");
      const [emailErr, setEmailErr] = useState("");
      const emailHandler = (e) => {
            const { id, value, name } = e.target;
            const validEmailRegex = RegExp(
                  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            );
            setEmail(value)
            if (value.length === 0) {
                  setEmailErr("Required");
            } else if (!validEmailRegex.test(value)) {
                  setEmailErr("Invalid Email");
            } else {
                  setEmailErr(false);
            }
      }

      const submitNewsLatter = () => {
            const validEmailRegex = RegExp(
                  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            );
            if (email === "") {
                  setEmailErr("Email is required");
            } else if (!validEmailRegex.test(email)) {
                  setEmailErr("Invalid Email");
            }
            else {
                  props.newsletter({ email: email })
                  setEmail("");
            }
      }

      // handleSelect(key) {
      //       if (key === 1)
      //           this.setState({ heading: "Log in" })
      //       else
      //           this.setState({ heading: "Sign up" })
      //   }
      return (
            <>
                  {loading ? <SiteLoader /> : null}
                  <Header />
                  <MobileHeader />
                  <div className="hero_banner position-relative newspr-banner">
                        <Container className="position-relative">
                              <div className="ser_vi news-head" style={{ justifyContent: "center" }}>
                                    <div className="mb-lg-5 mb-4">
                                          <h5 className="bootcamp-heading text-center">Media & PR</h5>
                                    </div>
                              </div>

                              {/* Media content */}
                              {
                                    newsList && newsList.length !== 0 ?
                                          newsList.map((news, i) => {
                                                return (
                                                      i === 0 &&
                                                      <a href={news.post_link} key="main-post" target="_blank" rel="" className="main-poster mb-5 d-block">
                                                            <span className="news-deco"></span>
                                                            <img src={news.media_image_url} className="img-fluid" alt="media poster" />
                                                            <div className="main-mediaBlock">
                                                                  <h6>{news.date} - Marketing</h6>
                                                                  <h3>{news.title}</h3>
                                                                  <div className="auther-info">
                                                                        <img src={imageurl.mediaPoster} />
                                                                        <p className="mb-0">Guy Hawkins</p>
                                                                  </div>
                                                            </div>
                                                      </a>
                                                )
                                          }) :
                                          <h2 className="text-center text-muted">Post Not Found</h2>
                              }
                              {/* End:: Media content */}

                              {/* Begin:: banner list */}
                              <div className="row banner-post g-xl-5">
                                    {
                                          newsList && newsList.length !== 0 ?
                                                newsList.map((news, i) => {
                                                      return (
                                                            i < 3 &&
                                                            <Col md={4} className="media-list" key={"latest" + i}>
                                                                  <a href={news.post_link} target="_blank" rel="">
                                                                        <img src={news.media_image_url} alt={news.title} />
                                                                        <h6>{news.date} - Marketing</h6>
                                                                        <h4 className="fw-bold">{news.title}</h4>
                                                                        <div className="auther-info">
                                                                              <img src={imageurl.mediaPoster} />
                                                                              <p className="mb-0">Guy Hawkins</p>
                                                                        </div>
                                                                  </a>
                                                            </Col>
                                                      )
                                                }) :
                                                <h2 className="text-center text-muted">Post Not Found</h2>
                                    }
                              </div>
                              {/* End:: banner list */}
                        </Container>
                  </div >

                  {/* Begin:: Subscribe block */}
                  <Container className="subscribe-sec">
                        <Row className="align-items-center">
                              <Col lg={6} md={5}>
                                    <h2 className="mb-0 text-center text-md-left"><span>Subscribe</span> to our Newsletter</h2>
                              </Col>
                              <Col lg={6} md={7}>
                                    <div className="subscribe-input">
                                          <input type="text" placeholder="Enter Your Email" className="form-control" onChange={emailHandler} value={email} />
                                          <button type="submit" className="btn btn-orange" onClick={submitNewsLatter}>Subscribe</button>
                                    </div>
                                    {emailErr && (<span className="text-danger small d-block"> * {emailErr} </span>)}
                              </Col>
                        </Row>
                  </Container>
                  {/* End:: Subscribe block */}

                  <Features />
                  {/* Begin:: latest media */}

                  <Container className="latest-media">
                        <div className="ser_vi bookacal d-inline-block">
                              <h5 className="fw-bold position-relative mb-0">Latest Media</h5>
                        </div>
                        <Tabs
                              id=""
                              activeKey={key}
                              onSelect={(k) => setKey(k)}
                              className="mb-3"
                        >
                              <Tab eventKey="All" title="All">
                                    <div className="latest-post">
                                          <Row className="g-4">
                                                {
                                                      newsList && newsList.length !== 0 ?
                                                            newsList.map((news, i) => {
                                                                  return (<>
                                                                        <Col md={i == 0 ? 12 : 6} key={news.id} className={i !== 0 && "mini-post"}>
                                                                              <a href={news.post_link} target="_blank" className="d-block main-poster mb-4">
                                                                                    <img src={news.media_image_url} className="img-fluid" alt="media poster" />
                                                                                    <div className="main-mediaBlock">
                                                                                          <h6>{news.date} - Marketing</h6>
                                                                                          <h3 className="">{news.title}</h3>
                                                                                          <img src={news.logo_url} className="ferms-img" alt="ferms" />
                                                                                    </div>
                                                                              </a>
                                                                        </Col>
                                                                  </>)
                                                            }) :
                                                            <h2 className="text-center text-muted">Post Not Found</h2>
                                                }
                                          </Row>
                                    </div>
                              </Tab>
                              <Tab eventKey="Local" title="Local">
                                    <div className="latest-post">
                                          <Row>
                                                {
                                                      newsList?.map((news, i) => {
                                                            if (news.type === "1") {
                                                                  return (
                                                                        <Col md={i == 0 ? 12 : 6
                                                                        } key={"Local" + i} className={i !== 0 && "mini-post"}>
                                                                              <a href={news.post_link} target="_blank" className="d-block main-poster mb-4">
                                                                                    <img src={news.media_image_url} className="img-fluid" alt="media poster" />
                                                                                    <div className="main-mediaBlock">
                                                                                          <h6>{news.date} - Marketing</h6>
                                                                                          <h3>{news.title}</h3>
                                                                                          <img src={news.logo_url} className="ferms-img" alt="ferms" />
                                                                                    </div>
                                                                              </a>
                                                                        </Col>
                                                                  )
                                                            }
                                                      })
                                                }
                                          </Row>
                                    </div>
                              </Tab>
                              <Tab eventKey="Regional" title="Regional" >
                                    <div className="latest-post">
                                          <Row>
                                                {
                                                      newsList && newsList.length !== 0 ?
                                                            newsList.map((news, i) => {
                                                                  if (news.type === "2") {
                                                                        return (
                                                                              <Col md={i == 0 ? 12 : 6
                                                                              } key={"Regional" + i} className={i !== 0 && "mini-post"}>
                                                                                    <a href={news.post_link} target="_blank" className="d-block main-poster mb-4">
                                                                                          <img src={news.media_image_url} className="img-fluid" alt="media poster" />
                                                                                          <div className="main-mediaBlock">
                                                                                                <h6>{news.date} - Marketing</h6>
                                                                                                <h3>{news.title}</h3>
                                                                                                <img src={news.logo_url} className="ferms-img" alt="ferms" />
                                                                                          </div>
                                                                                    </a>
                                                                              </Col>
                                                                        )
                                                                  }
                                                            }) :
                                                            <h2 className="text-center text-muted">Post Not Found</h2>
                                                }
                                          </Row>
                                    </div>
                              </Tab>
                              <Tab eventKey="Global" title="Global" >
                                    <div className="latest-post">
                                          <Row>
                                                {
                                                      newsList && newsList.length !== 0 ?
                                                            newsList.map((news, i) => {
                                                                  if (news.type === "3") {
                                                                        return (
                                                                              <Col md={i == 0 ? 12 : 6
                                                                              } key={"Global" + i} className={i !== 0 && "mini-post"}>
                                                                                    <a href={news.post_link} target="_blank" className="d-block main-poster mb-4">
                                                                                          <img src={news.media_image_url} className="img-fluid" alt="media poster" />
                                                                                          <div className="main-mediaBlock">
                                                                                                <h6>{news.date} - Marketing</h6>
                                                                                                <h3>{news.title}</h3>
                                                                                                <img src={news.logo_url} className="ferms-img" alt="ferms" />
                                                                                          </div>
                                                                                    </a>
                                                                              </Col>
                                                                        )
                                                                  }
                                                            }) :
                                                            <h2 className="text-center text-muted">Post Not Found</h2>
                                                }
                                          </Row>
                                    </div>
                              </Tab>
                        </Tabs>
                  </Container>
                  {/* Begin:: latest media */}
                  <Footer />
            </>
      );
}

const mapStateToProp = state => ({
      getNews: state.newsPrList,
})

const mapDispatchToProps = dispatch => ({
      newsletter: payload => dispatch(newsletter(payload)),
      getNewsPrList: () => dispatch(getNewsPr()),
})

export default connect(mapStateToProp, mapDispatchToProps)(withRouter(NewsPr));