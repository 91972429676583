import React, { useState } from 'react'
import { Container, Row, Col, } from "react-bootstrap";
import imageurl from '../common/images';
import Carousel from 'react-multi-carousel';
import { Icon } from '@iconify/react';
export const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
      const { carouselState: { currentSlide } } = rest;
      return (
            <div className="carousel-button-group_event">
                  <button className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()} > <img src={imageurl.longLeft} alt="" /></button>
                  <button onClick={() => next()} ><img src={imageurl.longRight} alt="" /></button>
                  {/* <Button style={{background:"red",position:"absolute",left:"0" ,bottom:0}} onClick={() => goToSlide(currentSlide + 1)}> Go to any slide </Button> */}
            </div>
      );
};
export const Review = () => {
      const responsive = {
            superLargeDesktop: {
                  // the naming can be any, depends on you.
                  breakpoint: { max: 4000, min: 3000 },
                  items: 3,
                  partialVisibilityGutter: 40

            },
            desktop: {
                  breakpoint: { max: 3000, min: 1200 },
                  items: 3,
                  partialVisibilityGutter: 15,
                  centerMode: true,
            },
            tablet: {
                  breakpoint: { max: 1199, min: 767 },
                  items: 2,
                  partialVisibilityGutter: 15
            },
            mobile: {
                  breakpoint: { max: 767, min: 0 },
                  items: 1,
                  partialVisibilityGutter: 20
            },
      };
      return (
            <>
                  <div className='Review mt-5 dev-review'>
                        <Container>
                              <Row><Col className='text-center'><h5 style={{ fontSize: "60px", fontWeight: "600" }}>
                                    See what our
                                    <p style={{ color: "#E18427" }} className="mb-0 d-inline"> Founders<br /></p> <span>are saying!</span>
                              </h5></Col></Row>

                              <Carousel responsive={responsive} infinite={true} renderButtonGroupOutside={true} arrows={false} customButtonGroup={<ButtonGroup />}>
                                    <div className='px-4'>
                                          <div className="open_talk">
                                                <p className='para'>“I am blown away by how dedicated ConvrtX was to supporting a founder's vision. The lessons I've learned along the way are beyond valuable.”</p>
                                          </div>
                                          <div className="follow_box">
                                                <div className="first_box">
                                                      <img src={imageurl.user} alt="" />
                                                      <div className="user_name">
                                                            <h5>Carter Olive</h5>
                                                            <p>Founder of StayBusy</p>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    <div className='px-4'>
                                          <div className="open_talk">
                                                <p className='para'>“ConvrtX has helped us go from vision, to launch and supported with everything in between. They are really quite holisitc, in what they do!”</p>
                                          </div>
                                          <div className="follow_box">
                                                <div className="first_box">
                                                      <img src={imageurl.tobusyowner} alt="" />
                                                      <div className="user_name">
                                                            <h5>Ayesha Imran</h5>
                                                            <p>Founder of 2Meeters</p>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    <div className='px-4'>
                                          <div className="open_talk">
                                                <p className='para'>"Being new to startup world, mean’t I had a lot to learn. But ConvrtX not only positioned me corretly for investors but taught me how to raise funds too!"</p>
                                          </div>
                                          <div className="follow_box">
                                                <div className="first_box">
                                                      <img src={imageurl.zoomowner} alt="" />
                                                      <div className="user_name">
                                                            <h5>Michael McNair</h5>
                                                            <p>Founder of SpotSwap</p>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </Carousel>
                        </Container>
                  </div>
            </>
      )
}
