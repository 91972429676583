import { Icon } from "@iconify/react";
import React from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getGuide } from "../../store/actions/commonAction";
import imageurl from "../common/images";
import MobPaginate from "../common/Paginate/MobPaginate";
import Paginate from "../common/Paginate/Paginate";
import { Features } from "../Landingpage/Features";
import { Footer } from "../Landingpage/Footer";
import { Header } from "../Landingpage/Header";
import { MobileHeader } from "../Landingpage/Mobile/MobileHeader";
import SiteLoader from "../SiteLoader/SiteLoader";
export const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
      const { carouselState: { currentSlide } } = rest;
      return (
            <div className="carousel-button-group_event slider-navs">
                  <button className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()} ><Icon icon="bi-arrow-left-short"  /></button>
                  <button onClick={() => next()} >  <Icon icon="bi-arrow-right-short"/></button>
                  {/* <button onClick={() => next()} >  <img src={imageurl.left} alt="" /></button> */}
                  {/* <Button style={{background:"red",position:"absolute",left:"0" ,bottom:0}} onClick={() => goToSlide(currentSlide + 1)}> Go to any slide </Button> */}
            </div>
      );
};

const Guide = ({ guideList, guides }) => {
      const responsive = {
            superLargeDesktop: {
                  // the naming can be any, depends on you.
                  breakpoint: { max: 4000, min: 3000 },
                  items: 5,
                  partialVisibilityGutter: 40

            },
            desktop: {
                  breakpoint: { max: 3000, min: 1200 },
                  items: 3,
                  partialVisibilityGutter: 15,
                  centerMode: true,
            },
            tablet: {
                  breakpoint: { max: 1199, min: 767 },
                  items: 2,
                  partialVisibilityGutter: 15
            },
            mobile: {
                  breakpoint: { max: 767, min: 0 },
                  items: 1,
                  partialVisibilityGutter: 20
            },
      };
      useEffect(() => {
            guideList()
      }, [])

      const guide = guides.guide.data;

      const handlePageClick = ({ selected: page }) => {
            guideList(page + 1);
      };
      return (
            <>
                  <Header />
                  <MobileHeader />
                  {/* Begin:: Guide banner */}
                  <div className="hero_banner guide-banner" >
                        <Container>
                              <Row>
                                    <Col lg={6}>
                                          <div className="ser_vi ">
                                                <div className="mb-5">
                                                      <h5>Explore Best Startup Guides From Our <span>Mentors</span></h5>
                                                      <p className="title-para mb-4">An Award Winning Venture Ecosystem Supporting Startup Entrepreneurs With A Formulaic & Holistic Structure.</p>
                                                      <div className="deck_btns">
                                                            <button className="talk_btn btn-register bg-orange">
                                                                  <span className="pe-2">Get Your Free Marketing Plan</span><Icon icon="bi:arrow-right-circle" color="white" />
                                                            </button>
                                                      </div>
                                                </div>
                                          </div>
                                    </Col>
                                    <Col lg={6}>
                                          <div className="business_img d-none d-lg-block">
                                                <img src={imageurl.guidesHero} className="guide-hero" alt="Guide hero" />
                                          </div>
                                    </Col>
                              </Row>
                        </Container>
                        <div className="business_img d-lg-none ">
                              <img src={imageurl.guidesHero} className="guide-hero" alt="Guide hero" />
                        </div>
                  </div>
                  {/* End:: Guide banner */}

                  {/* Begin:: Most Recent Guides */}
                  {guides.loading ? <SiteLoader /> : null}
                  <Container fluid className="recent-guides px-0">
                        <Container className="position-relative">
                              <div className="ser_vi text-center text-md-start">
                                    <h5 className="title_track new_common mb-0 fw-bold">
                                          Most Recent
                                    </h5>
                              </div>

                              <div className="">
                                    {
                                          guide &&
                                                guide.length > 0 ?
                                                <Carousel responsive={responsive} infinite={true} renderButtonGroupOutside={true} arrows={false} customButtonGroup={<ButtonGroup />}>
                                                      {
                                                            guide.map((guide, i) => {
                                                                  if (i < 6) {
                                                                        return (
                                                                              <Link to={"/guides/" + guide.title} className="d-block" key={"recent" + i}>
                                                                                    <div className="social-list">
                                                                                          <img src={guide.image_url} className="img-fluid" alt="social-image" />
                                                                                    </div>
                                                                                    <div className="px-3">
                                                                                          <h6 className="text-dark">{guide.title}</h6>
                                                                                    </div>
                                                                              </Link>
                                                                        );
                                                                  }
                                                            })
                                                      }
                                                </Carousel> :
                                                <div className="empty-post">No post found</div>
                                    }

                              </div>
                              <div className="text-center">
                                    <Link to="/startaproject" className="talk_btn">
                                          {/* <span className="pe-2">Start a Project</span><Icon icon="bi:arrow-right-circle" color="white" /> */}
                                          <span className="pe-2">Start a Project</span><Icon icon="bi:arrow-right-circle" color="white" />
                                    </Link>
                              </div>
                        </Container>
                  </Container>
                  {/* End:: Most Recent Guides */}

                  {/* Begin:: Startup Guides */}
                  <Container className="startup-guide">
                        <div className="ser_vi text-center ">
                              <h5 className="title_track new_common mb-0 fw-bold">
                                    Read Our <span>Startup Guides</span>
                              </h5>
                        </div>
                        {
                              guide &&
                                    guide.length > 0 ?
                                    <Row className="mt-5">
                                          {guide.map((guide, i) => {
                                                return (
                                                      <Col sm={6} key={"Guide" + i}>
                                                            <Link to={"/guides/" + guide.title} className="guide-card">
                                                                  <img src={guide.image_url} className="guide-hero" alt="Guide hero" />
                                                                  <h4 className="fw-bold">{guide.title}</h4>
                                                            </Link>
                                                      </Col>
                                                );
                                          })}
                                    </Row> :
                                    <div className="empty-post">No post found</div>
                        }

                        {/* {guide && guide.length > 0 && ( */}
                        <Col lg={12} className="text-center mt-4">
                              <div className="pagination d-none d-lg-flex">
                                    <Paginate
                                          totalCounts={guides.total_record}
                                          perPage={guides.per_page}
                                          currentPage={guides.currentPage}
                                          handlePageClick={handlePageClick}
                                    />
                              </div>
                              <div className="pagination d-lg-none">
                                    <MobPaginate
                                          totalCounts={guides.total_record}
                                          perPage={guides.per_page}
                                          currentPage={guides.currentPage}
                                          handlePageClick={handlePageClick}
                                    />
                              </div>
                        </Col>
                        {/* )} */}
                  </Container>
                  {/* End:: Startup Guides */}
                  <Features />
                  <Footer />
            </>
      )
}


const mapStateToProp = state => ({
      guides: state.guide,

})
const mapDispatchToProp = dispatch => ({
      guideList: (page) => dispatch(getGuide(page))
})
export default connect(mapStateToProp, mapDispatchToProp)(withRouter(Guide));