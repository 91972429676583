import { Icon, InlineIcon } from "@iconify/react";
import React from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getLatestpodcastList } from "../../store/actions/commonAction";
import imageurl from "../common/images";
import { Features } from "../Landingpage/Features";
import { Footer } from "../Landingpage/Footer";
import { Header } from "../Landingpage/Header";
import { MobileHeader } from "../Landingpage/Mobile/MobileHeader";


export const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
      const { carouselState: { currentSlide } } = rest;
      return (
            <div className="carousel-button-group_event slider-navs">
                  <button className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()} ><Icon icon="bi-arrow-left-short" /></button>
                  <button onClick={() => next()} >  <Icon icon="bi-arrow-right-short" /></button>
                  {/* <button onClick={() => next()} >  <img src={imageurl.left} alt="" /></button> */}
                  {/* <Button style={{background:"red",position:"absolute",left:"0" ,bottom:0}} onClick={() => goToSlide(currentSlide + 1)}> Go to any slide </Button> */}
            </div>
      );
};
const Podcast = ({ Latestpodcast, latestPodcastList }) => {

      const responsive = {
            superLargeDesktop: {
                  // the naming can be any, depends on you.
                  breakpoint: { max: 4000, min: 3000 },
                  items: 5,
                  partialVisibilityGutter: 40

            },
            desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 3,
                  partialVisibilityGutter: 15
            },
            tablet: {
                  breakpoint: { max: 1024, min: 767 },
                  items: 2,
                  partialVisibilityGutter: 15
            },
            mobile: {
                  breakpoint: { max: 767, min: 0 },
                  items: 1,
                  partialVisibilityGutter: 15
            },
      };

      // let count = 0;
      useEffect(() => {
            Latestpodcast();
      }, [])
      const latestPodcast = latestPodcastList.latestPodcast;
      return (
            <>
                  <Header />
                  <MobileHeader />
                  <div className="hero_banner business_merge podcast-banner">
                        <Container >
                        <img className="head-star" src={imageurl.founder_highlight} alt="ico" />
                              <Row>
                                    <Col lg={6}>
                                          <div className="ser_vi position-relative">
                                                <img src="https://convrtx.com/images/highlight.png" alt="ico" height="auto" className="position-absolute top-0 star-icon"/>
                                                <div className="pt-5">
                                                      <h5>The Truth Of A <br />
                                                            <span> Founder.</span>
                                                      </h5>
                                                      <p className="title-para mb-5">
                                                            Behind every founder there’s a story to be told, within our episdoes we will cover the stories of founders, fund managers and board members of highly succsessful startups.
                                                      </p>
                                                      <div className="deck_btns mt-4">
                                                            <button className="talk_btn btn-register bg-orange">
                                                                  <span className="pe-2">Browse Episodes</span>
                                                                  <InlineIcon icon="bi:arrow-right-circle" color="white" />
                                                            </button>
                                                      </div>
                                                </div>
                                          </div>
                                    </Col>
                                    <Col lg={6} className="pt-3">
                                          <div className="">
                                                <img src={imageurl.podcastDesk} className="img-fluid d-none d-md-block" alt="podcast" />
                                                <img src={imageurl.podcastMob} className="img-fluid d-md-none" alt="podcast" />
                                          </div>
                                    </Col>
                              </Row>
                        </Container>
                  </div>

                  <Container className="latest-episode-sec position-relative">
                        <div className="ser_vi text-center text-md-start">
                              <h5 className="title_track new_common mb-0">
                                    Latest Episodes
                              </h5>
                        </div>
                        <div className="episode-slider">
                              <Carousel responsive={responsive} infinite={true} renderButtonGroupOutside={true} arrows={false} customButtonGroup={<ButtonGroup />}>
                                    {/* {latestPodcast && latestPodcast} */}
                                    {
                                          latestPodcast.map((latest, i) => {
                                                return (<div key={"latest" + i}>
                                                      <Link to={`/podcast/Ep` + latest.id}>
                                                            <div className="episode-list">
                                                                  <div>
                                                                        {i + 1}
                                                                  </div>
                                                            </div>
                                                      </Link>
                                                      <div className="px-4">
                                                            <h5>{latest.podcast_title}</h5>
                                                            <p className="text-muted">{latest.summary}</p>
                                                      </div>
                                                      {/* <Link to={`/podcast/Ep` + latest.id}> <img src={imageurl.playbtn} className="img-fluid" alt="play btn" /></Link> */}
                                                </div>
                                                )
                                          })
                                    }

                              </Carousel>
                        </div>
                  </Container>

                  {/* Begin: Most viewed Episode*/}
                  <Container fluid className="viewed-episode">
                        <div className="ser_vi text-center text-white">
                              <h5 className="title_track new_common mb-0">
                                    Most Viewed, <span>Episodes.</span>
                              </h5>
                        </div>

                        <Container>
                              <Col lg={10} className="episode-container mx-auto">
                                    {

                                          latestPodcast && latestPodcast.length > 0 ?
                                                latestPodcast.map((latest, i) => {
                                                      return (
                                                            <div className="episode-item" key={"podcast" + i}>
                                                                  <div className="thumbnail order-0 ">
                                                                        <img className="img-fluid" alt="Thumbnail" src={latest.image_url
                                                                        } />
                                                                  </div>
                                                                  <div className=" episode-detail order-2 order-md-1">
                                                                        <small>{latest.guests}</small>
                                                                        <h4>{latest.podcast_title}</h4>
                                                                        <p className="mb-2">{latest.summary}</p>
                                                                        <p className="fw-bold mb-0">{new Date(latest.length * 1000).toISOString().slice(14, 19)}</p>
                                                                  </div>
                                                                  <div className="order-1 order-md-2 me-4">
                                                                        <Link to={`/podcast/Ep` + latest.id}> <img src={imageurl.playbtn} className="img-fluid" alt="play btn" /></Link>
                                                                  </div>
                                                            </div>
                                                      )
                                                })
                                                :
                                                <div className="text-center">
                                                      {/* <div className="thumbnail order-0  ">
                                                            <div className="thumbnail order-0 ">
                                                                  <img className="img-fluid" alt="Thumbnail" src="https://artsmidnorthcoast.com/wp-content/uploads/2014/05/no-image-available-icon-6.png" />
                                                            </div>
                                                      </div> */}
                                                      <div className="empty-post episode-detail order-2 order-md-1">
                                                            No data found
                                                      </div>

                                                </div>
                                    }
                              </Col>
                              <div className="text-center">
                                    <Link to="/podcast-more" className="talk_btn btn-register bg-orange">
                                          <span className="pe-2">View All</span>
                                          <InlineIcon icon="bi:arrow-right-circle" color="white" />
                                    </Link>
                              </div>
                        </Container>
                  </Container>
                  {/* End: Most viewed Episode*/}
                  <Features />
                  <Footer />
            </>
      )
}

const mapStateToProps = state => ({
      latestPodcastList: state.latestPodcastList,
})

const mapDispatchToProps = dispatch => ({
      Latestpodcast: () => dispatch(getLatestpodcastList())
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Podcast));
// export default Podcast;