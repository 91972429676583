//useAnalyticsEventTracker.jsx
// import React from "react";
import ReactGA from "react-ga";
ReactGA.initialize('UA-167776092-1');

const useAnalyticsEventTracker = (category="category") => {
  const eventTracker = (action = "test action", label = "test label") => {
    // console.log('check tracking', category, action, label, ReactGA.event({category: category, action: action, label: label}));
    ReactGA.event({category: category, action: action, label: label});
  }
  return eventTracker;
}
export default useAnalyticsEventTracker;