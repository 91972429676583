import { FETCH_LATESTALLPODCAST_LIST, FETCH_LATESTALLPODCAST_LIST_ERROR, FETCH_GUIDE, FETCH_GUIDE_ERROR, FETCH_INSTAGRAM, FETCH_INSTAGRAM_ERROR, FETCH_LATEST_PODCAST, FETCH_LATEST_PODCAST_ERROR, FETCH_NEWSPR_ERROR, FETCH_NEWSPR_LIST, FETCH_TIKTOK, FETCH_TIKTOK_ERROR, FETCH_YOUTUBE, FETCH_YOUTUBE_ERROR, SET_GUIDE, SET_INSTAGRAM, SET_LATEST_PODCAST, SET_NEWSPR_LIST, SET_TIKTOK, SET_YOUTUBE, SET_LATESTALLPODCAST_LIST, RESET_CHAPTER_GUIDE, SET_CHAPTER_GUIDE, SET_CHAPTER_DETAIL, RESET_CHAPTER_DETAIL, FETCH_INFLUENCER, SET_INFLUENCER, FETCH_INFLUENCER_ERROR, RESET_INFLUENCER, FETCH_PODCAST_DETAIL, SET_PODCAST_DETAIL, RESET_PODCAST_DETAIL } from "../common/types";

const newsPrState = {
      error: "",
      loading: false,
      newsPr: [],
}

export const newsPrReducer = (state = newsPrState, action) => {
      switch (action.type) {
            case FETCH_NEWSPR_LIST:
                  return {
                        ...state,
                        loading: true,
                  }
            case SET_NEWSPR_LIST:
                  return {
                        ...state,
                        loading: false,
                        newsPr: action.payload
                  }

            case FETCH_NEWSPR_ERROR:
                  return {
                        ...state,
                        loading: false,
                        error: action.payload
                  }
            default:
                  return state
      }
}

const latestPodcastState = {
      error: "",
      loading: false,
      latestPodcast: [],
}

export const latestPodcatReducer = (state = latestPodcastState, action) => {
      switch (action.type) {
            case FETCH_LATEST_PODCAST:
                  return {
                        ...state,
                        loading: true,
                  }

            case SET_LATEST_PODCAST:
                  return {
                        ...state,
                        loading: false,
                        latestPodcast: action.payload
                  }

            case FETCH_LATEST_PODCAST_ERROR:
                  return {
                        ...state,
                        loading: false,
                        error: action.payload
                  }
            default:
                  return state
      }
}


const instagramState = {
      error: "",
      loading: false,
      instagram: [],
}

export const instagramReducer = (state = instagramState, action) => {
      switch (action.type) {
            case FETCH_INSTAGRAM:
                  return {
                        ...state,
                        loading: true,
                  }
            case SET_INSTAGRAM:
                  return {
                        ...state,
                        loading: false,
                        instagram: action.payload
                  }
            case FETCH_INSTAGRAM_ERROR:
                  return {
                        ...state,
                        loading: false,
                        error: action.payload
                  }
            default:
                  return state
      }
}

const tiktokState = {
      error: "",
      loading: false,
      tiktok: [],
}

export const tiktokReducer = (state = tiktokState, action) => {
      switch (action.type) {
            case FETCH_TIKTOK:
                  return {
                        ...state,
                        loading: true,
                  }
            case SET_TIKTOK:
                  return {
                        ...state,
                        loading: false,
                        tiktok: action.payload
                  }
            case FETCH_TIKTOK_ERROR:
                  return {
                        ...state,
                        loading: false,
                        error: action.payload
                  }
            default:
                  return state
      }
}
const youtubeState = {
      error: "",
      loading: false,
      youtube: [],
}

export const youtubeReducer = (state = youtubeState, action) => {
      switch (action.type) {
            case FETCH_YOUTUBE:
                  return {
                        ...state,
                        loading: true,
                  }
            case SET_YOUTUBE:
                  return {
                        ...state,
                        loading: false,
                        youtube: action.payload
                  }
            case FETCH_YOUTUBE_ERROR:
                  return {
                        ...state,
                        loading: false,
                        error: action.payload
                  }
            default:
                  return state
      }
}
const GuiudeState = {
      error: "",
      loading: false,
      guide: [],
      currentPage: 1,
      per_page: 0,
      total_record: 0,
      chapter: {},
      chapter_detail:{}
}
export const GuideListReducer = (state = GuiudeState, action) => {
      switch (action.type) {
            case FETCH_GUIDE:
                  return {
                        ...state,
                        loading: true,
                  }
            case SET_GUIDE:
                  return {
                        ...state,
                        loading: false,
                        ...action.pagination,
                        guide: action.payload,
                  }
            case FETCH_GUIDE_ERROR:
                  return {
                        ...state,
                        loading: false,
                        currentPage: 1,
                        per_page: 0,
                        total_record: 0,
                        error: action.payload
                  }
            case SET_CHAPTER_GUIDE:
                  return { ...state, loading: false, chapter: action.payload };
            case RESET_CHAPTER_GUIDE:
                  return { ...state, loading: false, chapter: {} };
            case SET_CHAPTER_DETAIL:
                  return { ...state, loading: false, chapter_detail: action.payload };
            case RESET_CHAPTER_DETAIL:
                  return { ...state, loading: false, chapter_detail: {} };
            default:
                  return state
      }
}

const allpodcastState = {
      error: "",
      loading: false,
      allpodcast: [],
      currentPage: 1,
      per_page: 0,
      total_record: 0,
}

export const allpodcastReducer = (state = allpodcastState, action) => {
      switch (action.type) {
            case FETCH_LATESTALLPODCAST_LIST:
                  return {
                        ...state,
                        loading: true,
                  }
            case SET_LATESTALLPODCAST_LIST:
                  return {
                        ...state,
                        loading: false,
                        ...action.pagination,
                        allpodcast: action.payload
                  }

            case FETCH_LATESTALLPODCAST_LIST_ERROR:
                  return {
                        ...state,
                        loading: false,
                        allpodcast: [],
                        currentPage: 1,
                        per_page: 0,
                        total_record: 0,
                        error: action.payload
                  }
            default:
                  return state
      }
}
const influencerState = {
      error: "",
      loading: false,
      influencer: [],
}

export const influencerReducer = (state = influencerState, action) => {
      switch (action.type) {
            case FETCH_INFLUENCER:
                  return {
                        ...state,
                        loading: true,
                  }
            case SET_INFLUENCER:
                  return {
                        ...state,
                        loading: false,
                        influencer: action.payload
                  }

            case FETCH_INFLUENCER_ERROR:
                  return {
                        ...state,
                        loading: false,
                        error: action.payload
                  }
            case RESET_INFLUENCER:
                  return {
                        ...state,
                        loading: false,
                        influencer: []
                  }
            default:
                  return state
      }
}
const podcastDetailState = {
      error: "",
      loading: false,
      podcastDetail: [],
}

export const podcstDetailReducer = (state = podcastDetailState, action) => {
      switch (action.type) {
            case FETCH_PODCAST_DETAIL:
                  return {
                        ...state,
                        loading: true,
                  }
            case SET_PODCAST_DETAIL:
                  return {
                        ...state,
                        loading: false,
                        podcastDetail: action.payload
                  }

            case FETCH_PODCAST_DETAIL:
                  return {
                        ...state,
                        loading: false,
                        error: action.payload
                  }
            case RESET_PODCAST_DETAIL:
                  return {
                        ...state,
                        loading: false,
                        podcastDetail: []
                  }
            default:
                  return state
      }
}