import { Icon } from "@iconify/react";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import NumberScroller from "react-number-scroller";
import { Link } from "react-router-dom";
import { Header } from "../Bootcamp/Header";
import { MobileHeader } from "../Bootcamp/MobileHeader";
import imageurl from "../common/images";
import { Features } from "../Landingpage/Features";
import { Footer } from "../Landingpage/Footer";
import { StepsBusiness } from "../PitchDeckPages/Page1/StepsBusiness";
import { Fundarise } from "./Fundarise";
import Industries from "./Industries";
import { Review } from "./Review";
import { StartupsTrust } from "./StartupsTrust";


const Devloper = () => {
      return (
            <>
                  <Header />
                  <MobileHeader />
                  <div className="hero_banner common-banner bg-green pb-0">
                        <Container className="position-relative">
                              <div className="ser_vi ">
                                    <div className="text-white resp-ps">
                                          <h5>
                                                Build your <span style={{fontStyle:"italic"}}>&#123;mobile_apps&#125;</span> Faster with Professional Developers
                                          </h5>
                                    </div>
                              </div>
                              <Row className="gx-5">
                                    <Col lg={5}>
                                          <div className="ser_vi ">
                                                <div className="mb-5 mobile-text">
                                                      <span className="arrow"></span>
                                                      <p className="title-para mb-5 text-white">ConvrtX is the global leader in Startup App Development. Over 10,000 startups worked with to-date. $4+ Billion in capital raised and counting.</p>
                                                      <div className="deck_btns pt-5">
                                                            <Link to="/startaproject" className="talk_btn btn-register bg-orange d-inline-block">
                                                                  <span className="pe-2">Start a project</span><Icon icon="bi:arrow-right-circle" color="white" />
                                                            </Link>
                                                      </div>
                                                </div>
                                          </div>
                                    </Col>
                                    <Col lg={7} className="">
                                          <div className="business_img">
                                                <img src={imageurl.devHero} className="img-fluid" alt="Guide hero" />
                                          </div>
                                    </Col>
                              </Row>
                        </Container>
                  </div>
                  {/* Begin:: industry listing */}
                  <Container className="industry-list dev-industry">
                        <div className="ser_vi text-center mb-3">
                              <h5 className="fw-bold"><span>Industries</span> We’ve Worked With</h5>
                        </div>
                        <h5 className="text-center">ConvrtX has worked with 1,000s of Startups from Pre-Seed to Series C.</h5>

                        <Row className="industry-container col-xl-8 mx-auto justify-content-center">
                              <Col lg={3} md={4} xs={6} className="text-center">
                                    <div className="ind-item fn">
                                          <img src={imageurl.finTech_ico} className="img-fluid" alt="Fin tech" />
                                          <h5>FinTech</h5>
                                    </div>
                              </Col>
                              <Col lg={3} md={4} xs={6} className="text-center">
                                    <div className="ind-item sn">
                                          <img src={imageurl.socialNetwork_ico} className="img-fluid" alt="Fin tech" />
                                          <h5>Social Networking</h5>
                                    </div>
                              </Col>
                              <Col lg={3} md={4} xs={6} className="text-center">
                                    <div className="ind-item bd">
                                          <img src={imageurl.bigData_ico} className="img-fluid" alt="Fin tech" />
                                          <h5>AI/Big Data</h5>
                                    </div>
                              </Col>
                              <Col lg={3} md={4} xs={6} className="text-center">
                                    <div className="ind-item w3">
                                          <img src={imageurl.web3_ico} className="img-fluid" alt="Fin tech" />
                                          <h5>Web 3.0</h5>
                                    </div>
                              </Col>
                              <Col lg={3} md={4} xs={6} className="text-center">
                                    <div className="ind-item ss">
                                          <img src={imageurl.sass_ico} className="img-fluid" alt="Fin tech" />
                                          <h5>SaaS</h5>
                                    </div>
                              </Col>
                              <Col lg={3} md={4} xs={6} className="text-center">
                                    <div className="ind-item ht">
                                          <img src={imageurl.healthTech_ico} className="img-fluid" alt="Fin tech" />
                                          <h5>Health Tech</h5>
                                    </div>
                              </Col>
                              <Col lg={3} md={4} xs={6} className="text-center">
                                    <div className="ind-item b2b">
                                          <img src={imageurl.b2b_ico} className="img-fluid" alt="Fin tech" />
                                          <h5>B2B</h5>
                                    </div>
                              </Col>
                        </Row>
                  </Container>
                  {/* End:: industry listing */}
                  <div className="bg-f6"><Features /></div>
                  <div className="fundraise-sec"><Fundarise /></div>
                  <StepsBusiness />
                  <div className="Dev-White"><StartupsTrust /></div>
                  <div className="fundarise trust" id="included">
                        <Container>
                              <Row>
                                    <Col>
                                          {" "}
                                          <div className="fundarise_details">
                                                <h5>
                                                      What is included in our custom <br />
                                                      <span>App Devlopment?</span>
                                                </h5>
                                          </div>
                                    </Col>
                              </Row>
                              <Row>
                                    <Row>
                                          <Col lg={4} className="ps-4">
                                                <ul className="customlist fw-bold">
                                                      <li>
                                                            <Icon icon="akar-icons:circle-check" />
                                                            <span>Build Plan</span>
                                                      </li>
                                                      <li>
                                                            <Icon icon="akar-icons:circle-check" />
                                                            <span>Wireframes</span>
                                                      </li>
                                                      <li>
                                                            <Icon icon="akar-icons:circle-check" />
                                                            <span>Logic Flow</span>
                                                      </li>

                                                      <li>
                                                            <Icon icon="akar-icons:circle-check" />
                                                            <span>User Interface</span>
                                                      </li>
                                                      <li>
                                                            <Icon icon="akar-icons:circle-check" />
                                                            <span>User Experience</span>
                                                      </li>
                                                      <li>
                                                            <Icon icon="akar-icons:circle-check" />
                                                            <span>Functional Requirements Doc</span>
                                                      </li>
                                                      <li>
                                                            <Icon icon="akar-icons:circle-check" />
                                                            <span>Development Strategy</span>
                                                      </li>
                                                </ul>
                                          </Col>
                                          <Col lg={4} className="ps-4">
                                                <ul className="customlist fw-bold">
                                                      <li>
                                                            <Icon icon="akar-icons:circle-check" />
                                                            <span>Monitization Strategy</span>
                                                      </li>

                                                      <li>
                                                            <Icon icon="akar-icons:circle-check" />
                                                            <span>Frontend Development</span>
                                                      </li>
                                                      <li>
                                                            <Icon icon="akar-icons:circle-check" />
                                                            <span>Backend Devlopment</span>
                                                      </li>
                                                      <li>
                                                            <Icon icon="akar-icons:circle-check" />
                                                            <span>Server Setup</span>
                                                      </li>
                                                      <li>
                                                            <Icon icon="akar-icons:circle-check" />
                                                            <span>API Selection</span>
                                                      </li>
                                                      <li>
                                                            <Icon icon="akar-icons:circle-check" />
                                                            <span>API Integration Setup</span>
                                                      </li>
                                                      <li>
                                                            <Icon icon="akar-icons:circle-check" />
                                                            <span>QA / UAT support</span>
                                                      </li>
                                                </ul>
                                          </Col>
                                          <Col lg={4} className="ps-4">
                                                <ul className="customlist fw-bold">
                                                      <li>
                                                            <Icon icon="akar-icons:circle-check" />
                                                            <span>Agile Development Methodology</span>
                                                      </li>
                                                      <li>
                                                            <Icon icon="akar-icons:circle-check" />
                                                            <span>Publication Assistance</span>
                                                      </li>
                                                      <li>
                                                            <Icon icon="akar-icons:circle-check" />
                                                            <span>Branding Support</span>
                                                      </li>
                                                      <li>
                                                            <Icon icon="akar-icons:circle-check" />
                                                            <span>Ongoing Maintenance & Support</span>
                                                      </li>
                                                      <li>
                                                            <Icon icon="akar-icons:circle-check" />
                                                            <span>Future Version Support</span>
                                                      </li>
                                                      <li>
                                                            <Icon icon="akar-icons:circle-check" />
                                                            <span>Growth Strategy</span>
                                                      </li>
                                                      <li>
                                                            <Icon icon="akar-icons:circle-check" />
                                                            <span>Management & HR Support</span>
                                                      </li>
                                                </ul>
                                          </Col>
                                    </Row>
                              </Row>
                        </Container>
                  </div>


                  <div className='business_pitch'>
                        <Container>
                              <div className="get_box">
                                    <Row>
                                          <Col>
                                                <h5>Wanna Launch an <span>App</span> <br />for your <span>Startup?</span></h5>
                                                <p>ConvrtX is a team of professional researchers, designers, and<br/> developers. Get the app for your startup.</p>
                                          </Col>
                                    </Row>
                                    <Row>
                                          <Col className="text-center"> <Link className="open_talk" to="/ContactUs">
                                                <Link className="open_talk" to="#startaproject">
                                                      <button className="talk_btn btn-register btn-svg-size">
                                                            <span className="pe-2" >Let’s Get Started</span>
                                                            <Icon icon="bi:arrow-right-circle" color="white" />
                                                      </button>
                                                </Link>
                                          </Link></Col>
                                    </Row>
                              </div>
                        </Container>
                  </div>

                  <Review />

                  <Container fluid className="devloper-pitch-form">
                        <Container className="mb-5 mt-0">
                              <div className="col-xl-8 mx-auto">
                                    <div className="mb-4 text-center form-landing pitch-text mt-0">
                                          <h5 className="mb-4" >
                                                <span> Need an </span>
                                                <p style={{ color: "#E18427" }} className="mb-0 d-inline">
                                                      {" "}App
                                                </p>
                                                <p className="mb-0 d-inline">   for <br /> your <span style={{ color: "#E18427" }}>Startup</span></p>
                                          </h5>
                                          <p className="form-info">Answer simple questions to help us evaluate,<br /> what’s the best package for you.</p>
                                    </div>

                                    {/* <button className="talk_btn d-block w-100 btn-next" type="button" onClick={setShowForm(true)}> */}
                                    <button className="talk_btn d-block w-100 btn-next" type="button" >
                                          <span className="pe-2">Let’s Get Started</span>
                                          <Icon icon="bi:arrow-right-circle" color="white" />
                                    </button>
                              </div>
                              {/* <label className="text-center d-block text-center mt-4"> <img src={imageurl.check} className="img-fluid me-2" alt="check ico" /> <span className="fw-bold">{leadCount}</span> People filled this form.</label> */}
                              <label className="text-center d-block text-center mt-4"> <img src={imageurl.check} className="img-fluid me-2" alt="check ico" /> <span className="fw-bold numscroll-block"><NumberScroller from={100} to={10597} timeout={0} /></span> People filled this form.</label>
                        </Container>
                  </Container>
                  <Footer />
            </>
      )
}

export default Devloper;