import { BOOTCAMP_INIT, BOOTCAMP_SUBMIT, BOOTCAMP_SUCCESS, RESET_BOOTCAMP_SUBMIT } from "../common/types";


const initialState = {
      success: false,
      submitting: false,
    };

export const bootcampReducer = (state = initialState, action) => {
      switch (action.type) {
        case BOOTCAMP_INIT:
          return {
            ...state,
            success: false,
          };
        case BOOTCAMP_SUCCESS:
          return {
            ...state,
            success: true,
          };
        case BOOTCAMP_SUBMIT:
          return {
            ...state,
            submitting: true,
          };
        case RESET_BOOTCAMP_SUBMIT:
          return {
            ...state,
            submitting: false,
          };
        default:
          return state;
      }
    };
    export default bootcampReducer;
    