import React from "react";
import ReactPaginate from "react-paginate";
// import imageurl from "../images";
import "./Paginate.css";

const MobPaginate = ({ totalCounts, perPage, currentPage, handlePageClick }) => {
  const pageCount = Math.ceil(totalCounts / perPage);
  if (pageCount) {
    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={pageCount}
        forcePage={currentPage - 1}
        // pageRangeDisplayed={5}
        pageRangeDisplayed={1}
        marginPagesDisplayed={1}
        breakLabel="..."
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
    );
  }
  return false;
};

export default MobPaginate;
