import React from "react";
import imageurl from "./common/images";
import { Footer } from "./Landingpage/Footer";
import { Header2 } from "./Landingpage/Header2";
import { MobileHeader2 } from "./Landingpage/Mobile/MobileHeader2";


const NotFound = () => {
      return (<>
            <div className="bg-green">
                  <Header2 />
                  <MobileHeader2 />
            </div>
            <div className="text-center">
                  <img src={imageurl.notFound} className="img-fluid" alt="404 Not found" />
            </div>
            <div className="ser_vi text-center notfound-text">
                  <div className="notfound-text">
                        <h5>
                              My Bad, <span>&#123;404&#125;</span><div className="d-inline-block"> Page Not Found</div>
                        </h5>
                  </div>
            </div>

            <Footer />

      </>)
}

export default NotFound;