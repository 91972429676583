import { Icon, InlineIcon } from "@iconify/react";
import { connect } from "react-redux";
import React from "react";
import { useEffect } from "react";
import { Col, Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { getGuide, getLatestallpodcastList } from "../../store/actions/commonAction";
import { Features } from "../Landingpage/Features";
import { Footer } from "../Landingpage/Footer";
import { Header } from "../Policy/Header";
import { MobileHeader } from "../Policy/MobileHeader";
import imageurl from "../common/images";
import Paginate from "../common/Paginate/Paginate";


const PodcastMore = ({ allpodcast, allPodcastList }) => {

      useEffect(() => {
            allpodcast();
      }, [])

      const PodcastPagination = allPodcastList.allpodcast;

      const allPodcast = allPodcastList.allpodcast.data;
      console.log(allPodcast, "allpodcast");

      const handlePageClick = ({ selected: page }) => {
            allpodcast(page + 1);
      };

      return (
            <>
                  <Header />
                  <MobileHeader />
                  <Container fluid className="policy-banner">
                        <Container className="pb-5">
                              <Link to="/podcast" className="btn btn-link btn-back text-white fw-normal position-static ps-0">
                                    <Icon icon="bi:arrow-left" color="#E18427" />
                                    Back
                              </Link>
                              <div className="ser_vi text-center text-white">
                                    <h5 className="title_track_2 new_common mb-0">
                                          All Podcasts, <span>Episodes</span>
                                    </h5>
                              </div>
                              <Col lg={10} className="episode-container mx-auto">
                                    {
                                          allpodcast && allpodcast.length > 0
                                                ?
                                                allPodcast?.map((latest, i) => {
                                                      return (
                                                            <div className="episode-item" key={"Latest" + i}>
                                                                  <div className="thumbnail order-0 ">
                                                                        <img className="img-fluid" alt="Thumbnail" src={latest.image_url} />
                                                                  </div>
                                                                  <div className="episode-detail order-2 order-md-1">
                                                                        <small>{latest.guests
                                                                        }</small>
                                                                        <h4>{latest.podcast_title}</h4>
                                                                        <p className="mb-2">{latest.summary}</p>
                                                                        <p className="fw-bold mb-0">{new Date(latest.length * 1000).toISOString().slice(14, 19)}</p>
                                                                  </div>
                                                                  <div className="order-1 order-md-2 me-4">
                                                                        <Link to={`/podcast/Ep` + latest.id}> <img src={imageurl.playbtn} className="img-fluid" alt="play btn" /></Link>
                                                                  </div>
                                                            </div>
                                                      )
                                                }) :
                                                <div className="text-center">
                                                      {/* <div className="thumbnail order-0  ">
                                                      <div className="thumbnail order-0 ">
                                                            <img className="img-fluid" alt="Thumbnail" src="https://artsmidnorthcoast.com/wp-content/uploads/2014/05/no-image-available-icon-6.png" />
                                                      </div>
                                                </div> */}
                                                      <div className="empty-post episode-detail order-2 order-md-1">
                                                            No data found
                                                      </div>

                                                </div>

                                    }
                              </Col>
                              {allPodcast && allPodcast.length > 0 && (
                                    <>
                                          <div className="text-center d-lg-none">
                                                <button onClick={handlePageClick} className="talk_btn btn-register bg-orange">
                                                      <span className="pe-2">Load more</span>
                                                      <InlineIcon icon="bi:arrow-right-circle" color="white" />
                                                </button>
                                          </div>
                                          <Col lg={12} className="text-center mt-4 d-none d-lg-block">
                                                <div className="pagination">
                                                      <Paginate
                                                            totalCounts={PodcastPagination.total}
                                                            perPage={PodcastPagination.per_page}
                                                            currentPage={PodcastPagination.current_page}
                                                            handlePageClick={handlePageClick}
                                                      />
                                                </div>
                                          </Col>
                                    </>
                              )}
                        </Container>
                  </Container>
                  <Features />
                  <Footer />
            </>
      )
}

const mapStateToProps = state => ({
      allPodcastList: state.allPodcastList,
})

const mapDispatchToProps = dispatch => ({
      allpodcast: (page) => dispatch(getLatestallpodcastList(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PodcastMore));