import React, { useState } from "react";
import { Container, Navbar, Dropdown, Nav } from "react-bootstrap";
import imageurl from "../common/images";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import ReactGA from 'react-ga';
import { useEffect } from "react";
import { HashLink as NavLink } from 'react-router-hash-link';

export const Header = () => {
      const [show, setShow] = useState(false);
      const handleShow = () => setShow(true);
      const handleClose = () => setShow(false);
      useEffect(() => {
            ReactGA.pageview(window.location.pathname + window.location.search);
      }, []);
      const [color, setColor] = useState(false);
      const changeColor = () => {
            if (window.scrollY >= 90) {
                  setColor(true);
            } else {
                  setColor(false);
            }
      };
      window.addEventListener("scroll", changeColor);
      return (
            <>
                  {/* <Register show={show} onClose={handleClose} /> */}
                  <div className="header">
                        <Navbar className={color ? "header header_bg" : "header header-transparent"}
                              expand="lg">
                              <Container>
                                    <Navbar.Brand as={Link} to="/">
                                          <img src={imageurl.logo} />
                                    </Navbar.Brand>
                                    <Navbar.Toggle aria-controls="navbarScroll" />
                                    <Navbar.Collapse id="navbarScroll">
                                          <Nav className="m-auto my-2 my-lg-0">
                                                <NavLink className="nav-link d-block" smooth to="#aboutBootcamp" >About Us</NavLink>
                                                <Nav.Link as={Link} smooth to="/">Our Process</Nav.Link>
                                                <Nav.Link as={Link} smooth to="/">Samples</Nav.Link>
                                                <Nav.Link as={Link} smooth to="/">Packages</Nav.Link>
                                                <Nav.Link as={Link} smooth to="/">What’s Included</Nav.Link>
                                                <NavLink className="nav-link d-block" smooth to="#bootcampReview" >Reviews</NavLink>
                                          </Nav>
                                          <Nav className="m-auto my-2 my-lg-0">
                                                {/* <button className="talk_btn">Let's talk</button> */}
                                                <Link className="open_talk" to="/startaproject">
                                                <button className="talk_btn" onClick={handleShow}>
                                                      <span>Start  A  Project</span>{" "}
                                                      <Icon icon="bi:arrow-right-circle" color="white" />
                                                </button>
                                                </Link>
                                          </Nav>
                                    </Navbar.Collapse>
                              </Container>
                        </Navbar>
                  </div>
            </>
      );
};
