import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { BlogDetail } from "../components/Blog/BlogDetail";
import { BlogLayout } from "../components/Blog/BlogLayout";
import { ContactLayout } from "../components/Contact/ContactLayout";
import { EventDetail } from "../components/Events/EventDetail";
import { EventLayout } from "../components/Events/EventLayout";
import Landingpagelayout from "../components/Landingpage/Landingpagelayout";
import PartnershipLayout from "../components/Partnerships/PartnershipLayout";
import PitchDeskBusiness from "../components/PitchDeckPages/Page1/PitchDeskBusiness";
import PitchDeskDesign from "../components/PitchDeckPages/Page2/PitchDeskDesign";
import { PortfolioDetail } from "../components/Portfolio/PortfolioDetail";
import { PortfolioLayout } from "../components/Portfolio/PortfolioLayout";
import OpenAdvisorLayout from "../components/Programs/OpenAdvisor/OpenAdvisorLayout";
import PitchForm from "../components/Programs/Pitch/PitchForm";
import { PitchLayout } from "../components/Programs/Pitch/PitchLayout";
import ThankYou from "../components/Programs/Pitch/ThankYou";
import { LaunchStrategy } from "../components/Services/LaunchStrategy";
import { ProductDevelopment } from "../components/Services/ProductDevelopment";
import { RaisingCapital } from "../components/Services/RaisingCapital";

import { ServiceDetailAdvisory } from "../components/Services/ServiceDetailAdvisory";
import { ServiceLayout } from "../components/Services/ServiceLayout";
import BusinessPlan from "../components/PitchDeckPages/Page3/BusinessPlan"
import ThankYou2 from "../components/Programs/Pitch/ThankYou2";

import { TermsCondition } from "../components/Policy/TermsConditions";
import failoryLanding from "../components/PitchDeckPages/Page1/failoryLanding";
import PitchDeckForm from "../components/PitchDeckPages/PitchDeckForm";
import { PrivacyPolicy } from "../components/Policy/PrivacyPolicy";
import Paco from "../components/PitchDeckPages/Page1/Paco";
import Bootcamp from "../components/Bootcamp/Bootcamp";
import NewsPr from "../components/News/NewsPr";
import Podcast from "../components/Podcast/Podcast";
import PodcastMore from "../components/Podcast/PodcastMore";
import Detail from "../components/Podcast/Detail";
import Influencer from "../components/Influencer/Influencer";
import Social from "../components/Social/Social";
import Guide from "../components/Guide/Guide";
import GuideChapter from "../components/Guide/GuideChapters";
import Chapter from "../components/Guide/Chapter";
import Startup from "../components/StaticPages/Startup";
import Mobile from "../components/StaticPages/Mobile";
import Devloper from "../components/StaticPages/Developer";
import NotFound from "../components/NotFound";

export const Router = () => {
  return (
    <div>
      <BrowserRouter basename="/">
        <Switch  >
          <Route exact path="/" component={Landingpagelayout} />
          <Route exact path="/services" component={ServiceLayout} />
          <Route
            exact
            path="/services/strategy_advisory"
            component={ServiceDetailAdvisory}
          />
          <Route
            exact
            path="/services/raising_capital"
            component={RaisingCapital}
          />
          <Route
            exact
            path="/services/product_development"
            component={ProductDevelopment}
          />
          <Route
            exact
            path="/services/Launch_Strategy"
            component={LaunchStrategy}
          />
          {/* <Route
            exact
            path="/Services-detail"
            component={ServiceDetailAdvisory}
          /> */}
          {/*<Route exact path="/portfolio" component={PortfolioLayout} />*/}
          <Route
            exact
            path="/portfolio/:param/:id"
            component={PortfolioDetail}
          />
          <Route exact path="/startaproject" component={ContactLayout} />

          <Route exact path="/events" component={EventLayout} />
          <Route exact path="/events/:id" component={EventDetail} />
          <Route exact path="/blog" component={BlogLayout} />
          <Route exact path="/blogdetail" component={BlogDetail} />
          <Route exact path="/blog/:param/:id" component={BlogDetail} />
          {/* <Route exact path="/" component={PartnershipLayout} /> */}
          <Route exact path="/partnerships" component={PartnershipLayout} />
          <Route exact path="/pitchcompetition" component={PitchLayout} />
          <Route exact path="/applyforpitch" component={PitchForm} />
          <Route exact path="/thankyou1" component={ThankYou} />
          <Route exact path="/thankyou" component={ThankYou2} />
          <Route exact path="/programs" component={OpenAdvisorLayout} />

          {/* New three Pages */}
          <Route exact path="/strategy" component={PitchDeskBusiness} />
          <Route exact path="/paco" component={Paco} />
          <Route exact path="/Pitchdeck" component={PitchDeskDesign} />
          <Route exact path="/Businessplan" component={BusinessPlan} />

          {/* failory landing page */}
          <Route exact path="/failory" component={failoryLanding}></Route>

          {/* Policy pages */}
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-conditions" component={TermsCondition} />
          <Route exact path="/pitchdeck-form" component={PitchDeckForm} />

          <Route exact path={"/Bootcamp"} component={Bootcamp} />
          {/*<Route exact path={"/news-pr"} component={NewsPr} />*/}
          <Route exact path={"/podcast"} component={Podcast} />
          <Route exact path={"/podcast-more"} component={PodcastMore} />
          <Route exact path="/podcast/:id" component={Detail} />

          {/* Influencer */}
          <Route exact path="/influencer/:name" component={Influencer} />
          {/*<Route exact path="/social" component={Social} />*/}
          {/*<Route exact path="/guides" component={Guide} />*/}
          <Route exact path="/guides/:name" component={GuideChapter} />
          <Route exact path="/guides/:name/:id" component={Chapter} />
          <Route exact path="/startup" component={Startup} />
          <Route exact path="/mobile" component={Mobile} />
          <Route exact path="/Developer" component={Devloper} />
          
          <Route exact path="/404" component={NotFound} />
          <Redirect to="/404" />

        </Switch>
      </BrowserRouter>
    </div>
  );
};
