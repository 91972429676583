import { Icon } from "@iconify/react";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import ScrollspyNav from "react-scrollspy-nav";
import { getChapterDetails } from "../../store/actions/commonAction";
import { RESET_CHAPTER_DETAIL } from "../../store/common/types";
import imageurl from "../common/images";
import { convertHTMLToString } from "../common/_helper";
import { Features } from "../Landingpage/Features";
import { Footer } from "../Landingpage/Footer";
import { Header } from "../Landingpage/Header";
import { MobileHeader } from "../Landingpage/Mobile/MobileHeader";
import SiteLoader from "../SiteLoader/SiteLoader";

const Chapter = () => {
      const { id } = useParams();
      const { loading, chapter_detail } = useSelector(
            (state) => state.guide
      );
      const detail = chapter_detail;
      console.log(detail)
      const dispatch = useDispatch();
      useEffect(() => {
            dispatch(getChapterDetails(id));
            return () => dispatch({ type: RESET_CHAPTER_DETAIL });
      }, [id, dispatch]);
      useEffect(() => {
            window.scroll(0, 0);
      }, []);
      return (
            <>
                  {loading ? <SiteLoader /> : null}
                  <Header />
                  <MobileHeader />
                  {/* Begin:: Chapter */}
                  <div className="hero_banner common-banner chapter-detail" >
                        <Container>
                              <Row>
                                    <Col lg={6}>
                                          <div className="ser_vi ">
                                                <div className="mb-5">
                                                      <h5>{detail.title}</h5>
                                                      <div className="auther-info chapter-auther mt-0">
                                                            <img src={imageurl.mediaPoster} alt="auther" />
                                                            <p className="mb-0">
                                                                  <b>Evan Oliver</b><br />
                                                                  Director of Marketing
                                                            </p>
                                                      </div>
                                                      <div className="deck_btns">
                                                            <button className="talk_btn btn-register bg-orange">
                                                                  <span className="pe-2">Get Your Free Marketing Plan</span><Icon icon="bi:arrow-right-circle" color="white" />
                                                            </button>
                                                      </div>
                                                </div>
                                          </div>
                                    </Col>
                                    <Col lg={6} className="text-lg-end text-center">
                                          <div className="business_img">
                                                <img src={imageurl.chapterHero} className="img-fluid" alt="Guide hero" />
                                          </div>
                                    </Col>
                              </Row>
                        </Container>
                  </div>
                  {/* End:: Chapter */}

                  {/* Begin:: Chapter in Detail */}
                  <Container fluid className="position-relative chapter-inDetail">
                        <Container >
                              <Row>
                                    <Col lg={4}>
                                          <span className="chapter-no">Chapter {detail.chapter_no} :</span>
                                          <div className="detail-list">
                                                <ScrollspyNav scrollTargetIds={["section_1", "section_2", "section_3"]}
                                                      offset={650}
                                                      activeNavClass="is-active"
                                                      scrollDuration="200">
                                                      <ul className="topic-listing">
                                                            {
                                                                  detail?.resources?.map((resource, i) => {
                                                                        return <li key={"resource" + i}><a href={"#resource" + resource.id}>{resource.title}</a></li>
                                                                  })
                                                            }
                                                      </ul>
                                                </ScrollspyNav>
                                          </div>
                                    </Col>
                                    <Col lg={8}>
                                          <div>
                                                <p>{detail.description}</p>
                                                {
                                                      detail?.resources?.length > 0 &&
                                                      <h3>Jump to:</h3>
                                                }
                                                <ul className="topic-listing mt-3">
                                                      {/* <li><Link to="#section_1">What Is Email Marketing?</Link></li> */}
                                                      {
                                                            detail?.resources?.map((resource, i) => {
                                                                  return <li key={"resource" + i}><a href={"#resource" + resource.id}>{resource.title}</a></li>
                                                            })
                                                      }
                                                </ul>
                                          </div>
                                          {
                                                detail?.resources?.map((resource, i) => {
                                                      return (
                                                            <div id={"resource" + resource.id} className="mb-5">
                                                                  {convertHTMLToString(resource.description)}
                                                                  {/* <h5>{parseHTML(detail.summary)}</h5> */}
                                                                  {/* <h2>What is email marketing?</h2>
                                                                  <p>Email marketing is… well, marketing done through email.</p>

                                                                  <p>It’s a marketing channel that uses email content to share business updates, sell products and services, and engage with your audience.</p>

                                                                  <p>You’ll often see marketing emails from those in the eCommerce biz, but just about any enterprise or industry can use and benefit from email marketing.</p>

                                                                  <p>Email marketing can target potential customers, existing customers, and even former customers to</p>

                                                                  <ul>
                                                                        <li>announce new products</li>
                                                                        <li>follow up for feedback</li>
                                                                        <li>set up autoresponders</li>
                                                                        <li>revive abandoned carts and reclaim sales</li>
                                                                        <li>promote special offers, discounts, and sales</li>
                                                                        <li>share important business updates and events</li>
                                                                        <li>share information such as webinars and reports</li>
                                                                        <li>welcome new customers</li>
                                                                        <li>lead customers to landing pages</li>
                                                                        <li>+ so much more</li>
                                                                  </ul>

                                                                  <p>Further, successful email marketing can build brand loyalty, bring more value to your customers, and straight up boost leads and sales.</p>

                                                                  <p>In fact, marketers who leveraged segmented campaigns saw as much as a 760% increase in revenue. Phew. 😅</p>

                                                                  <p>Now that we have a better idea of what email marketing is, let’s look at its magic components.</p> */}
                                                            </div>
                                                      )
                                                })
                                          }
                                    </Col>
                              </Row>
                        </Container>
                  </Container>

                  <Features />
                  <Footer />
                  {/* End:: Chapter in Detail */}
            </>
      );
}

export default Chapter;