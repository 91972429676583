import axios from "axios";
import { toast } from "react-toastify";
import { getCountries, leadCount, pitchdeckSubmit, sendOtpApi, verifyOtpApi, addnewsletter } from "../api";
import { ERR_COUNTRIES, ERR_LEAD, GET_COUNTRIES, GET_LEAD, GET_LOADING, LOAD_LEAD, NEWSLETTER_ERROR, NEWSLETTER_SUBMIT, NEWSLETTER_SUCCESS, OTPVERIFY_ERROR, OTPVERIFY_SUBMIT, OTPVERIFY_SUCCESS, PITCHDECK_SUBMIT, PITCHDECK_SUCCESS, RESET_OTPVERIFY_SUBMIT, RESET_PITCHDECK_SUBMIT, RESET_SENDOTP_SUBMIT, SENDOTP_ERROR, SENDOTP_SUBMIT, SENDOTP_SUCCESS } from "../common/types";

export const getCountry = () => {
  return (dispatch) => {
    dispatch({
      type: GET_LOADING,
    })
    const requestOptions = {
      method: "GET",
      headers: {
        'content-type': 'application/json',
      },
      url: getCountries
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_COUNTRIES,
          payload: resp.data.data.countries,
        })
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status == 422) {
          dispatch({
            type: ERR_COUNTRIES,
            payload: error,
          });
        }
      });
  };
};


export const submitForm = (payload, resetForm, history) => async(dispatch) => {
  try {
      dispatch({
          type: PITCHDECK_SUBMIT,
      });
      // const { data } = await AXIOS.post(APIs.SEND_CONTACT_QUERY, {...payload });
      const data = {
        method: "POST",
        headers: {
          'content-type': 'application/json',
        },
        url: pitchdeckSubmit,
        data: payload
      };
      axios(data)
      .then((resp) => {
      console.log("submit form" , resp.data.data.lead_id)
      if (resp.data.success) {
          dispatch({  
            type: PITCHDECK_SUCCESS,
            lead_id : resp.data.data.lead_id,
            statusCode : 200
          });
          // resetForm();
        }else{
          dispatch({  
              type: OTPVERIFY_ERROR,
              statusCode : resp.data.status_code
          });
        }
      })
      .catch((error) => {
        let data = error.response;
        console.log("code 699" , data?.status )
        if (!error.response) {
          dispatch({
            type: RESET_PITCHDECK_SUBMIT,
            statusCode : 500
          });
        }
      if (data?.status == 422) {
        dispatch({
          type: RESET_PITCHDECK_SUBMIT,
          statusCode : 422
        });
      }
    });
      
  } catch (err) {
    dispatch({
        type: RESET_PITCHDECK_SUBMIT,
    });
      // toast.error(err.message);
  }
};

export const sendOtp = (payload, resetForm, history) => async(dispatch) => {
  try {
      dispatch({
          type: SENDOTP_SUBMIT,
      });
      // const { data } = await AXIOS.post(APIs.SEND_CONTACT_QUERY, {...payload });
      const data = {
        method: "POST",
        headers: {
          'content-type': 'application/json',
        },
        url: sendOtpApi,
        data: payload
      };
      axios(data)
      .then((resp) => {
        console.log("90" , resp.data.status_code)
        if (resp.data.status_code == 200) {
          dispatch({  
            type: SENDOTP_SUCCESS,
            otp : resp.data.data.otp,
          });
        }else{
          dispatch({  
            type: SENDOTP_ERROR,
            otp : "",
          });
        }
      })
      .catch((error) => {
        dispatch({
            type: RESET_SENDOTP_SUBMIT,
            otp : "",
        });
      });
      
  } catch (err) {
    dispatch({
        type: RESET_SENDOTP_SUBMIT,
        otp : "",
        statusCode : ""  
        
    });
      // toast.error(err.message);
  }
};
export const verifyOtpAction = (payload, resetForm, history) => async(dispatch) => {
  try {
      // const { data } = await AXIOS.post(APIs.SEND_CONTACT_QUERY, {...payload });
      const data = {
        method: "POST",
        headers: {
          'content-type': 'application/json',
        },
        url: verifyOtpApi,
        data: payload
      };
      axios(data)
      .then((resp) => {
        if (resp.data.success) {
          dispatch({  
            type: OTPVERIFY_SUCCESS,
            statusCode : 200
          });
          // resetForm();
        }else{
          dispatch({  
              type: OTPVERIFY_ERROR,
              statusCode : resp.data.status_code
          });
        }
      })
      .catch((error) => {
        let data = error.response;
        console.log("code" , data?.status )
        if (!error.response) {
          dispatch({
            type: RESET_OTPVERIFY_SUBMIT,
            statusCode : 500
          });
        }
      if (data?.status == 422) {
        dispatch({
          type: RESET_OTPVERIFY_SUBMIT,
          statusCode : 422
        });
      }


      });
  } catch (err) {
    dispatch({
        type: OTPVERIFY_ERROR,
        statusCode : 500
    });
      // toast.error(err.message);
  }
};

export const countLead = () => {
  return (dispatch) => {
    dispatch({
      type: LOAD_LEAD,
    })
    const requestOptions = {
      method: "GET",
      headers: {
        'content-type': 'application/json',
      },
      url: leadCount,
    };

    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_LEAD,
          payload: resp.data.data.counter,
        })
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status == 422) {
          dispatch({
            type: ERR_LEAD,
            payload: error,
          });
        }
      });
  };
};

export const newsletter = (payload) => async(dispatch) => {
  try {
      dispatch({
          type: NEWSLETTER_SUBMIT,
      });
      const data = {
        method: "POST",
        headers: {
          'content-type': 'application/json',
        },
        url: addnewsletter,
        data: payload
      };
      axios(data)
      .then((data) => {
        dispatch({
            type: NEWSLETTER_SUCCESS,
        });
        dispatch({
            type: NEWSLETTER_SUBMIT,
        });
        if (data.status) {
            toast.success(data.data.message);
        } else {
            toast.error(data.data.message);
        }
      })
      .catch((error) => {
        dispatch({
            type: NEWSLETTER_ERROR,
        });
      });
  } catch (err) {
    dispatch({
      type: NEWSLETTER_SUBMIT,
  });
      toast.error(err.message);
  }
};