// import { computeHeadingLevel } from "@testing-library/react";
import axios from "axios";
import { toast } from "react-toastify";
import { getAllPodcast, getChapterDetail, getGuideChapter, getGuideList, getInfluencer, getInstagram, getLatestPodcast, getNewsPrList, getpodcast, getpodcastDetail, getTiktok, getYoutube, influencerCount, totalListener, totalWatchTime } from "../api";
import { CLICK_COUNTED, FETCH_GUIDE, FETCH_GUIDE_ERROR, FETCH_INFLUENCER, FETCH_INFLUENCER_ERROR, FETCH_INSTAGRAM, FETCH_INSTAGRAM_ERROR, FETCH_LATESTALLPODCAST_LIST, FETCH_LATESTALLPODCAST_LIST_ERROR, FETCH_LATEST_PODCAST, FETCH_LATEST_PODCAST_ERROR, FETCH_NEWSPR_ERROR, FETCH_NEWSPR_LIST, FETCH_PODCAST_DETAIL, FETCH_PODCAST_DETAIL_ERROR, FETCH_TIKTOK, FETCH_TIKTOK_ERROR, FETCH_YOUTUBE, FETCH_YOUTUBE_ERROR, SET_CHAPTER_DETAIL, SET_CHAPTER_GUIDE, SET_GUIDE, SET_INFLUENCER, SET_INSTAGRAM, SET_LATESTALLPODCAST_LIST, SET_LATEST_PODCAST, SET_NEWSPR_LIST, SET_PODCAST_DETAIL, SET_TIKTOK, SET_YOUTUBE } from "../common/types"

import { useHistory } from "react-router-dom";
export const getNewsPr = (payload) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_NEWSPR_LIST });
        const { data } = await axios.post(getNewsPrList);
        if (data.status) {
            dispatch({
                type: SET_NEWSPR_LIST,
                payload: data.data || [],
            });
        } else {
            dispatch({
                type: SET_NEWSPR_LIST,
                payload: data.data || [],
            });
        }
    } catch (err) {
        toast.error(err.message);
        dispatch({
            type: FETCH_NEWSPR_ERROR,
            payload: err,
        });
    }
}

export const getLatestpodcastList = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_LATEST_PODCAST })
        const { data } = await axios.get(getpodcast);
        if (data.status) {
            dispatch({
                type: SET_LATEST_PODCAST,
                payload: data.data || [],
            });
        } else {
            dispatch({
                type: SET_LATEST_PODCAST,
                payload: data.data || [],
            });
        }
    } catch (err) {
        toast.error(err.message);
        dispatch({
            type: FETCH_LATEST_PODCAST_ERROR,
            payload: err,
        });
    }
}


export const getLatestallpodcastList = (page = 1) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_LATESTALLPODCAST_LIST })
        const { data } = await axios.post(`${getAllPodcast}?page=${page}`);
        if (data.status) {
            dispatch({
                type: SET_LATESTALLPODCAST_LIST,
                payload: data.data || [],
            });
        } else {
            dispatch({
                type: SET_LATESTALLPODCAST_LIST,
                payload: data.data || [],
            });
        }
    } catch (err) {
        toast.error(err.message);
        dispatch({
            type: FETCH_LATESTALLPODCAST_LIST_ERROR,
            payload: err,
        });
    }
}

export const getInstaPost = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_INSTAGRAM })
        const { data } = await axios.get(getTiktok);
        if (data.status) {
            dispatch({
                type: SET_INSTAGRAM,
                payload: data.data || [],
            });
        } else {
            dispatch({
                type: SET_INSTAGRAM,
                payload: data.data || [],
            });
        }
    } catch (err) {
        toast.error(err.message);
        dispatch({
            type: FETCH_INSTAGRAM_ERROR,
            payload: err,
        });
    }
}
export const getTiktokPost = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_TIKTOK })
        const { data } = await axios.get(getTiktok);
        if (data.status) {
            dispatch({
                type: SET_TIKTOK,
                payload: data.data || [],
            });
        } else {
            dispatch({
                type: SET_TIKTOK,
                payload: data.data || [],
            });
        }
    } catch (err) {
        toast.error(err.message);
        dispatch({
            type: FETCH_TIKTOK_ERROR,
            payload: err,
        });
    }
}
export const getYoutubePost = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_YOUTUBE })
        const { data } = await axios.get(getYoutube);
        if (data.status) {
            dispatch({
                type: SET_YOUTUBE,
                payload: data.data || [],
            });
        } else {
            dispatch({
                type: SET_YOUTUBE,
                payload: data.data || [],
            });
        }
    } catch (err) {
        toast.error(err.message);
        dispatch({
            type: FETCH_YOUTUBE_ERROR,
            payload: err,
        });
    }
}
export const getGuide = (page = 1) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_GUIDE })
        const { data } = await axios.post(`${getGuideList}?page=${page}`);

        if (data.status) {
            dispatch({
                type: SET_GUIDE,
                payload: data.data || [],
                pagination: {
                    currentPage: data.currentPage || 1,
                    per_page: data.per_page || 0,
                    total_record: data.total_record || 0,
                },
            });
        } else {
            dispatch({
                type: SET_GUIDE,
                payload: data.data || [],
                pagination: {
                    currentPage: 1,
                    per_page: 0,
                    total_record: 0,
                },
            });
        }
    } catch (err) {
        toast.error(err.message);
        dispatch({
            type: FETCH_GUIDE_ERROR,
            payload: err,
        });
    }
}


export const getChapterList = (guideId) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_GUIDE });
        const { data } = await axios.get(`${getGuideChapter}/${guideId}`);
        if (data.status) {
            dispatch({
                type: SET_CHAPTER_GUIDE,
                payload: {
                    ...data.data,
                },
            });
        }
    } catch (err) {
        toast.error(err.message);
        dispatch({
            type: FETCH_GUIDE_ERROR,
            payload: err,
        });
    }
};

export const getChapterDetails = (chapterId) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_GUIDE });
        const { data } = await axios.get(`${getChapterDetail}/${chapterId}`);
        if (data.status) {
            dispatch({
                type: SET_CHAPTER_DETAIL,
                payload: {
                    ...data.data,
                },
            });
        }
    } catch (err) {
        toast.error(err.message);
        dispatch({
            type: FETCH_GUIDE_ERROR,
            payload: err,
        });
    }
};

export const getInfluencerList = (name) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_INFLUENCER })
        const { data } = await axios.get(`${getInfluencer}/${name}`);
        if (data.status) {
            dispatch({
                type: SET_INFLUENCER,
                payload: data.data || [],
            });
        } else {
            useHistory.push("/404")
        }
    } catch (err) {
        toast.error(err.message);
        dispatch({
            type: FETCH_INFLUENCER_ERROR,
            payload: err,
        });
    }
}

export const getDetailList = (podcastID) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_PODCAST_DETAIL });
        const { data } = await axios.get(`${getpodcastDetail}/${podcastID}`);
        // console.log(cardId,"cardid");
        if (data.status) {
            dispatch({
                type: SET_PODCAST_DETAIL,
                payload: {
                    ...data.data,
                },
            });
        }
    } catch (err) {
        toast.error(err.message);
        dispatch({
            type: FETCH_PODCAST_DETAIL_ERROR,
            payload: err,
        });
    }
};

export const countInfluencerClick = async (Id) => {
    try {
      const { data } = await axios.post(`${influencerCount}`, {id : Id});
      if (data.status) {
        // dispatch({ type: FETCH_PODCAST_DETAIL });
        toast.success(data.message);
        return true;
      } else {
        toast.error(data.message);
        return false;
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  export const watchTimeCount = async (Id, watchTime) => {
    try {
      const { data } = await axios.post(`${totalWatchTime}`, {id : Id, total_time: watchTime});
      if (data.status) {
        toast.success(data.message);
        return true;
      } else {
        toast.error(data.message);
        return false;
      }
    } catch (err) {
      toast.error(err.message);
    }
  };
  export const totalListenerCount = async (Id) => {
    try {
      const { data } = await axios.post(`${totalListener}`, {id : Id});
      if (data.status) {
        toast.success(data.message);
        return true;
      } else {
        toast.error(data.message);
        return false;
      }
    } catch (err) {
      toast.error(err.message);
    }
  };