import { Icon, InlineIcon } from "@iconify/react";
import React, { useLayoutEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { Features } from "../Landingpage/Features";
import { Footer } from "../Landingpage/Footer";
import { useDispatch, useSelector } from "react-redux";
import { RESET_PODCAST_DETAIL } from "../../store/common/types";
import { useEffect } from "react";
import { getDetailList, totalListenerCount, watchTimeCount } from "../../store/actions/commonAction";
import Moment from 'moment';
import { MobileHeader2 } from "../Landingpage/Mobile/MobileHeader2";
import { Header2 } from "../Landingpage/Header2";
import { convertHTMLToString } from "../common/_helper";
import SiteLoader from "../SiteLoader/SiteLoader";
import { useRef } from "react";

const Detail = () => {
      const { id } = useParams();
      const podcastId = id.slice(2);
      const { loading, podcastDetail } = useSelector(
            (state) => state.podcastDetail
      );
      const detail = podcastDetail;
      const dispatch = useDispatch();
      useEffect(() => {
            dispatch(getDetailList(podcastId));
            return () => dispatch({ type: RESET_PODCAST_DETAIL });
      }, [id, dispatch]);

      useEffect(() => {
            window.scroll(0, 0);
      }, []);
      const player = useRef()
      const handlePause = (e) => {
            const getTime = player.current.audio.current.currentTime
            watchTimeCount(podcastId, getTime);
      }
      const countPlay = (e) => {
            totalListenerCount(podcastId);
      }
      useEffect(() => {
            window.scroll(0, 0);
      }, []);
      return (
            <>
                  {loading ? <SiteLoader /> : null}
                  <div className="bg-green">
                        <Header2 />
                  </div>
                  <MobileHeader2 />
                  <Container className="podcast-detail">
                        <Link to="/podcast" className="btn btn-link btn-back fw-normal position-static">
                              <Icon icon="bi:arrow-left" color="#E18427" />
                              Back
                        </Link>
                        <Row>
                              {
                                    <Col lg={5} md={6} >
                                          {
                                                detail ?
                                                      <img src={detail.image_url} className="img-fluid w-100" alt="Thumbnail" />
                                                      :
                                                      <img src="https://artsmidnorthcoast.com/wp-content/uploads/2014/05/no-image-available-icon-6.png" className="img-fluid w-100" alt="Thumbnail" />

                                          }
                                    </Col>
                              }
                              <Col lg={7} md={6}>
                                    {
                                          detail ?
                                                <div className="podcast-info d-flex align-items-start flex-column bd-highlight">
                                                      <div className="d-flex justify-content-between flex-wrap w-100">
                                                            <p>Guests: {detail.guests}</p>
                                                            {/* <p className="ms-auto">{detail.updated_at}</p> */}
                                                            <p className="ms-auto">{Moment(detail.created_at).format("MMMM DD, YYYY")}</p>

                                                      </div>
                                                      <h2>{detail.podcast_title}</h2>
                                                      <h5>{convertHTMLToString(detail.summary)}</h5>
                                                      <div className="podcast-share mt-auto">
                                                            <p className="mb-0 me-4">Share on</p>
                                                            <div>
                                                                  <Link to="#">
                                                                        <Icon icon="fa-facebook-f" />
                                                                  </Link>
                                                                  <Link to="#">
                                                                        <Icon icon="fa-twitter" />
                                                                  </Link>
                                                                  <Link to="#">
                                                                        <Icon icon="fa-linkedin" />
                                                                  </Link>
                                                            </div>
                                                      </div>
                                                      <AudioPlayer
                                                            ref={player}
                                                            autoPlay={false}
                                                            src={detail.audio_url}
                                                            onPlay={e => countPlay(e)}
                                                            onPause={e => handlePause(e)}
                                                            onEnded={e => handlePause(e)}
                                                      // other props here
                                                      />
                                                </div>
                                                :
                                                <div className="empty-post">No data found</div>
                                    }
                              </Col>
                        </Row>
                        <div className="text-center btn-podcastDetail">
                              <Link to="/startaproject" className="talk_btn btn-register text-white">
                                    <span className="pe-2">Start a Project</span>
                                    <InlineIcon icon="bi:arrow-right-circle" color="white" />
                              </Link>
                        </div>
                  </Container>
                  <Features />
                  <Footer />
            </>
      )
}
export default Detail; 