import React,{useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import imageurl from "../common/images";
import { Footer } from "../Landingpage/Footer";
import { Icon } from "@iconify/react";
import { Header } from "../Landingpage/Header";
import { Link } from "react-router-dom";
import { MobileHeader } from "../Landingpage/Mobile/MobileHeader";
import ReactGA from 'react-ga';

export const ServiceDetailAdvisory = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div id="top">
      <Header />
      <MobileHeader/>
      <div className="service_detail">
        <Container>
          <Row><Col><Link  to="/services"><h5 style={{color:"#000",textTransform:"uppercase",display:"flex",alignItems:"center" }}> <Icon icon="bytesize:arrow-left" style={{fontSize:"18px",color:"#e18427",marginRight:"12px"}} /><span>Services</span></h5></Link></Col></Row>
          <Row>
            <Col>
              {" "}
              <div className="left_box">
                <h5 className="mb-5">Strategy & Advisory</h5>
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg={6}>
              <div className="left_box">
                <div className="tags_box">
                  <span>Early Stage</span>
                  <span>Industry Research</span>
                  <span>Business Plan & Pitch Deck</span>
                  <span>Advisory Support</span>
                  <span>Startup Fundraising</span>
                  <span>Board Building</span>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <p className="serve_description">
                Starting with a plan of action helps you lay out a concrete path
                for the next few years. We will take your singular idea and amplify it to help you create a full-fledged, operational startup. 
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="banner_box">
                <img src={imageurl.bannerbig} alt="" />
                <p>
                Once you share your idea with us, we start researching the solution you want to introduce into the market. We’ll research everything from product development costs to possible competitors and differentiating factors. Our goal is to get you investor ready, and to do that, we provide the following services:

                </p>
                <ul>
                  <li>
                  <Icon icon="bi:arrow-right" color="#e18427" />
                    <span>Industry Research</span>
                  </li>
                  <li>
                    <Icon icon="bi:arrow-right" color="#e18427" />
                    <span>An investor-ready business plan and pitch deck</span>
                  </li>
                  <li>
                    <Icon icon="bi:arrow-right" color="#e18427" />
                    <span>Assistance with building your advisory board</span>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="banner_box">
                <img src={imageurl.strategyAdvise} alt="" />
                <p>
                With up-to-date insights in hand, our experts business strategists create a feasible route for your startup, outlining how you can use your value proposition to be a contender in the industry. We provide you with a tangible plan that helps you stay on track, and we share your idea with prospective investors who may be interested in funding your startup. 
                </p>
                <p>With comprehensive pitch decks, we guide you on how to get the attention of   VCs in board room meetings. With years of experience and knowledge of what goes on in investment circles, we can help you decide the best details to share during your pitch.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};
