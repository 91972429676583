import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { HashLink as NavLink } from 'react-router-hash-link';
import { Header } from "../PitchDeckPages/Page1/Header";
import { MobileHeader } from "../PitchDeckPages/Page1/MobileHeader";
import imageurl from "../common/images";
import PitchDeckForm from "../PitchDeckPages/PitchDeckForm";
import { Review } from "../PitchDeckPages/Page1/Review";
import { Footer } from "../Landingpage/Footer";
import { Features } from "../Landingpage/Features";
import { BusinessTypes } from "../PitchDeckPages/Page1/BusinessTypes";
import { StepsBusiness } from "../PitchDeckPages/Page1/StepsBusiness";
import { DownloadPdf } from "../PitchDeckPages/Page1/DownloadPdf";
import { OurPackages } from "../PitchDeckPages/Page1/OurPackages";
import { createPitchDuck } from "../../store/actions/authAction";
import { countInfluencerClick, getInfluencerList } from "../../store/actions/commonAction";
import { useEffect } from "react";
import { RESET_INFLUENCER } from "../../store/common/types";

const Influencer = () => {
  const { name } = useParams();
  const history = useHistory();
  const { loading, influencer } = useSelector(
    (state) => state.influencers
  );
  const detail = influencer;
  const dispatch = useDispatch();
  
  useEffect(() => {
    getInfluencerList(name);
    countInfluencerClick(detail.id)
    return () => dispatch({ type: RESET_INFLUENCER });
  }, [name, dispatch]);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div id="top">
      <Header />
      <MobileHeader />
      <div className="hero_banner business_merge influencer-bg">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="ser_vi ">
                <div className="mb-5">
                  <h5>
                    <p className=" mb-0 d-inline">The Worldwide Leader in</p>
                    <span> Pitch Deck & Business Plan Creation </span>
                  </h5>
                  <p className="title-para mb-4">
                    ConvrtX is the global leader in Business Plan & Pitch Deck
                    creation. Over 10,000 Business Plans & Pitch Decks Created
                    To-date. $4+ Billion in capital raised and counting.
                  </p>
                  <div className="deck_btns">
                    <NavLink smooth to="#startaproject">
                      <button className="talk_btn">
                        <span className="pe-2">Start a Project</span>
                        <Icon icon="bi:arrow-right-circle" color="white" />
                      </button>
                    </NavLink>
                    <NavLink smooth to="#samples">
                      <button className="talk_btn deck_btn ms-3">
                        <span className="pe-2">Our Work</span>
                        <Icon icon="bi:arrow-right-circle" color="#000" />
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} className="text-end">
              <div className="business_img1">
                <img src={detail.influencer_image} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <PitchDeckForm />
      {/* <FormBusiness
        createPitchDuck={createPitchDuck}
        submitting={submitting}
        history={history}
      /> */}
      <Features />
      <BusinessTypes />
      <StepsBusiness />
      <DownloadPdf />
      <OurPackages />
      <div className="review_grey" id="review">
        <Review />
      </div>
      {/* <GetPitch /> */}
      {/* <NeedBusinessForm
        createPitchDuck={createPitchDuck}
        submitting={submitting}
        history={history}
      /> */}
      <div className="pitch_startups">
        <PitchDeckForm />
      </div>
      <Footer />
    </div>
  );
};
const mapStateToProps = ({ form }) => {
  return {
    submitting: form.submitting,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createPitchDuck: (payload, resetForm, history) =>
      dispatch(createPitchDuck(payload, resetForm, history)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Influencer));
