import React from "react";
 import "./SiteLoader.css";
const SiteLoader = () => {
  return (
    <div className="loader-wrapper">
      <div className="loader">
      
      </div>
    </div>
  );
};
 
export default SiteLoader;
