import { Icon } from "@iconify/react";
import React, { useCallback, useState } from "react";
import { Container, FormGroup, FormLabel, ProgressBar, Spinner } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import OTPInput from "otp-input-react";
import imageurl from "../common/images";
import { countLead, getCountry, sendOtp, submitForm, verifyOtpAction } from "../../store/actions/pitchDeckAction";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import { DEF, RESET_OTPVERIFY_SUBMIT, RESET_PITCHDECK_SUBMIT } from "../../store/common/types";
import NumberScroller from 'react-number-scroller';
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";
// import DropdownItem from "react-bootstrap/esm/DropdownItem";

const PitchDeckForm = (props, submitForm) => {
      const gaEventTracker = useAnalyticsEventTracker('Start Project', 'Start Project');
      const dispatch = useDispatch()
      const history = useHistory()
      const getdata = props.getCountries;
      const leadData = props.getleads;
      useEffect(() => {
            getdata()
            leadData()
      }, [])
      useEffect(() => {
            // console.log("resssss" ,  props.resSendOtp.otp )
            setState((prevState) => ({
                  ...prevState,
                  otp: props.resSendOtp.otp
            }));
      }, [props.resSendOtp])
      const countryData = props.countryData.countries

      const [success, setSuccess] = useState(true);
      const [thankyou, setThankyou] = useState(true);
      const [showForm, setShowForm] = useState(false);
      const leadCount = props.leadCount.leads
      const [state, setState] = useState({
            name: "",
            email: "",
            phone: "",
            country: "231",
            industry: "",
            service: "",
            more_info: "",
            lead_id: "",
            otp: ""
      });
      const [countryCode, setCountryCode] = useState(1);
      const [now, setNow] = useState(10);
      const [incorrectOTP, setIncorrectOTP] = useState(false);

      const changeFormState = () => {
            setShowForm(true);
      }
      const [OTP, setOTP] = useState("");
      const [otpErr, setOtpErr] = useState(false)
      const verifyOtp = () => {
            setOtpErr(true);
            props.verifyOtpData({ country_code: countryCode, phone: state.phone, code: OTP, lead_id: state.lead_id })
      }
      useEffect(() => {
            if (props.verifyOtpRes.statusCode === 200 && thankyou) {
                  history.push("/thankyou");
                  setThankyou(false);
                  setState((prevState) => ({
                        ...prevState,
                        otp: ""
                  }));
                  dispatch({ type: RESET_OTPVERIFY_SUBMIT });
                  setThankyou(false)
            }
            // } 
            if (props.verifyOtpRes.statusCode == 422) {
                  // if(setIncorrectOTP){
                  setIncorrectOTP(true);
                  // }
                  dispatch({ type: RESET_OTPVERIFY_SUBMIT });
            }
      });

      const [number, setNumber] = useState(10597);
      useEffect(() => {
            if (props.pitchdeckSubmitForm.statusCode == 200) {
                  // console.log("state", state)
                  props.sendOtp({ phone: state.phone, country_code: countryCode })
                  setState((prevState) => ({
                        ...prevState,
                        lead_id: props.pitchdeckSubmitForm.lead_id
                  }));
                  setSteps((prevState) => ({
                        ...prevState,
                        step6: false,
                        step7: true
                  }));
                  setTimer(30);
                  setSpinner(false);
                  dispatch({ type: RESET_PITCHDECK_SUBMIT });
                  setNow(99)
            }
      })
      const [errors, setErrors] = useState({
            name: false,
            email: false,
            phone: false,
            country: false,
            industry: false,
            service: false,
            more_info: false,
      });

      const eventhandler = (e) => {
            const { id, value, name } = e.target;
            const validEmailRegex = RegExp(
                  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            );
            setState((prevState) => ({
                  ...prevState,
                  [id]: value,
            }));
            switch (name) {
                  case "name":
                        if (value.length === 0) {
                              setErrors((prevState) => ({
                                    ...prevState,
                                    name: "Required",
                              }));
                        } else {
                              setErrors((prevState) => ({
                                    ...prevState,
                                    name: false,
                              }));
                              setState((prevState) => ({
                                    ...prevState,
                                    name: value.replace(/[^A-Za-z ]/ig, '')
                              }))
                        }
                        break;
                  case "email":
                        if (value.length === 0) {
                              setErrors((prevState) => ({
                                    ...prevState,
                                    email: "Required",
                              }));
                        } else if (!validEmailRegex.test(value)) {
                              setErrors((prevState) => ({
                                    ...prevState,
                                    email: "Invalid Email",
                              }));
                        } else {
                              setErrors((prevState) => ({
                                    ...prevState,
                                    email: false,
                              }));
                        }
                        break;
                  case "phone":
                        const result = value.replace(/\D/g, '');
                           setState((prevState => ({
                              ...prevState,
                              phone: result
                        })))
                  
                        if (value.length === 0) {
                              setErrors((prevState) => ({
                                    ...prevState,
                                    phone: "Required",
                              }));
                        } else {
                              setErrors((prevState) => ({
                                    ...prevState,
                                    phone: false,
                              }));
                        }
                        break;
                  case "industry":
                        if (value.length == 0) {
                              setErrors((prevState) => ({
                                    ...prevState,
                                    industry: "Required",
                              }));
                        } else {
                              setErrors((prevState) => ({
                                    ...prevState,
                                    industry: false,
                              }));
                              setState((prevState => ({
                                    ...prevState,
                                    industry: value
                              })))
                        }
                        break;
                  case "service":
                        if (value.length == 0) {
                              setErrors((prevState) => ({
                                    ...prevState,
                                    service: "Required",
                              }));
                        } else {
                              setErrors((prevState) => ({
                                    ...prevState,
                                    service: false,
                              }));
                              setState((prevState => ({
                                    ...prevState,
                                    service: value
                              })))
                        }
                        break;
                  default:
            }
      };

      const [steps, setSteps] = useState({
            step1: true,
            step2: false,
            step3: false,
            step4: false,
            step5: false,
            step6: false,
            step7: false,
      })

      const step2Back = () => {
            setSteps((prevState) => ({
                  ...prevState,
                  step2: false,
                  step1: true
            }));
      }
      const step3Back = () => {
            setSteps((prevState) => ({
                  ...prevState,
                  step3: false,
                  step2: true
            }));
      }
      const step4Back = () => {
            setSteps((prevState) => ({
                  ...prevState,
                  step4: false,
                  step3: true
            }));
      }
      const step5Back = () => {
            setSteps((prevState) => ({
                  ...prevState,
                  step5: false,
                  step4: true
            }));
      }
      const step6Back = () => {
            setSteps((prevState) => ({
                  ...prevState,
                  step6: false,
                  step5: true
            }));
      }
      const step7Back = () => {
            setSteps((prevState) => ({
                  ...prevState,
                  step7: false,
                  step6: true
            }));
      }
      const nameCheck = (e) => {
            e.preventDefault()
            if (state.name === "") {
                  setErrors((prevState) => ({
                        ...prevState,
                        name: "Required",
                  }));
            } else {
                  setSteps((prevState) => ({
                        ...prevState,
                        step1: false,
                        step2: true
                  }))
                  setNow(20)
            }
      }
      const emailCheck = () => {
            const validEmailRegex = RegExp(
                  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            );
            if (state.email === "") {
                  setErrors((prevState) => ({
                        ...prevState,
                        email: "Required",
                  }));
            } else if (!validEmailRegex.test(state.email)) {
                  setErrors((prevState) => ({
                        ...prevState,
                        email: "Invalid Email",
                  }));
            } else {
                  setSteps((prevState) => ({
                        ...prevState,
                        step2: false,
                        step3: true
                  }))
                  setNow(40)
            }
      }
      const phoneCheck = () => {
            // console.log(state.phone.replaceAll(/\s/g, ''))
            if (state.phone === "") {
                  setErrors((prevState) => ({
                        ...prevState,
                        phone: "Required",
                  }));
            } else if (state.phone.replaceAll(/\s/g, '').length < 9) {
                  setErrors((prevState) => ({
                        ...prevState,
                        phone: "Too short.",
                  }));
            }
            // else if (state.country === "") {
            //       setErrors((prevState) => ({
            //             ...prevState,
            //             country: "country code is required",
            //       }));
            else {
                  setSteps((prevState) => ({
                        ...prevState,
                        step3: false,
                        step4: true
                  }));
                  setNow(60)
            }
      }
      const industryCheck = () => {
            if (state.industry === "") {
                  setErrors((prevState) => ({
                        ...prevState,
                        industry: "Required",
                  }));
            } else {
                  setSteps((prevState) => ({
                        ...prevState,
                        step4: false,
                        step5: true
                  }));
                  setNow(80)
            }
      }
      const serviceCheck = () => {
            if (state.service === "") {
                  setErrors((prevState) => ({
                        ...prevState,
                        service: "Required",
                  }));
            } else {
                  setSteps((prevState) => ({
                        ...prevState,
                        step5: false,
                        step6: true
                  }));
                  setNow(90)
            }
      }

      const [spinner, setSpinner] = useState(false);
      const MoreInfoCheck = () => {
            submitLeadForm();
            setSpinner(true);
            gaEventTracker('BJ - Start Project Form Submission');
      }
      const submitLeadForm = () => {
            props.submitForm({
                  full_name: state.name,
                  email: state.email,
                  phone_no: state.phone,
                  country: state.country,
                  industry: state.industry,
                  service: state.service,
                  more_info: state.more_info,
                  from_page_url: window.location.href
            })
      }
      const moreSkip = () => {
            setSpinner(false);
            submitLeadForm();
            gaEventTracker('BJ - Start Project Form Submission');
      }
      const [timer, setTimer] = useState(0);
      const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);
      useEffect(() => {
            timer > 0 && setTimeout(timeOutCallback, 1000);
      }, [timer, timeOutCallback]);

      const sendCodeAgain = () => {
            setOTP();
            setIncorrectOTP(false);
            props.sendOtp({ phone: state.phone, country_code: countryCode })
            setState((prevState) => ({
                  ...prevState,
                  lead_id: props.pitchdeckSubmitForm.lead_id
            }));
            setSpinner(false);
            setTimer(30);
      }
      const resetTimer = () => {
            if (!timer) {
                  setTimer(30);
                  props.sendOtp({ phone: state.phone, country_code: countryCode })
                  setState((prevState) => ({
                        ...prevState,
                        lead_id: props.pitchdeckSubmitForm.lead_id
                  }));
                  setSpinner(false);
            }
      };

      const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
            <a
                  href=""
                  ref={ref}
                  onClick={(e) => {
                        e.preventDefault();
                        onClick(e);
                  }}
            >
                  {children}
            </a>
      ));

      // forwardRef again here!
      // Dropdown needs access to the DOM of the Menu to measure it
      const CustomMenu = React.forwardRef(
            ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
                  const [value, setValue] = useState('');
                  // console.log("ref ", ref)
                  return (<>
                        <div
                              ref={ref}
                              style={style}
                              className={className}
                              aria-labelledby={labeledBy}
                        >

                              <div className="drpSearch">
                                    <Form.Control
                                          autoFocus
                                          className="my-3"
                                          placeholder="Search"
                                          onChange={(e) => setValue(e.target.value)}
                                          value={value}
                                    /></div>
                              <ul className="list-unstyled">
                                    {React.Children.toArray(children).filter(
                                          (child) =>
                                                // !value || child.props.children.toLowerCase().startsWith(value),
                                                // !value || child.props.children.toString().match(value),
                                                !value || child.props.children.toString().toUpperCase().match(value.toUpperCase()),
                                    )}
                              </ul>
                        </div>
                  </>
                  );
            }
      );
      const [selectedCountry, setSelectedCountry] = useState({
            phonecode: 1,
            code: "US"
      });
      const selectedValue = (v) => {
            setSelectedCountry(v);
            setState((prevState) => ({
                  ...prevState,
                  country: v.id,
            }))
            setCountryCode(v.phonecode)
      }

      return (<div id="startaproject" className="pt-0">
            {
                  showForm ? <>
                        <Container className="position-relative partnership_open leadForm pt-0 mt-0">
                              < div className="box mx-auto " >
                                    <div className="mb-4 text-center pitch-text">
                                          <h5 className="mb-4" >
                                                <p style={{ color: "#E18427" }} className="mb-0 d-inline">Ready</p>
                                                <p className="mb-0 d-inline"> to share your vision with us?</p>
                                          </h5>
                                          <p>Lets get started, Fill out the form below.</p>
                                    </div>
                                    <form onSubmit={e => { e.preventDefault() }}>
                                          {/* Step 1 */}
                                          {steps.step1 && <>
                                                <div className="deckformFields">
                                                      <label htmlFor="" className="Stepper-label">Nice to meet you, what’s your name?</label>
                                                      <FormGroup>
                                                            <FormLabel className="mb-2">Your Name</FormLabel>
                                                            <input type="text" placeholder="Example “John Smith”" value={state.name} className="form-control" id="name" name="name" onChange={eventhandler} />
                                                            {errors.name && (<span className="text-danger small d-block"> * {errors.name} </span>)}
                                                      </FormGroup>

                                                      <button className="talk_btn d-block w-100 btn-next" type="submit" onClick={nameCheck}>
                                                            <span className="pe-2">Next</span>
                                                            <Icon icon="bi:arrow-right-circle" color="white" />
                                                      </button>
                                                </div>
                                          </>
                                          }
                                          {/* Step 2 */}
                                          {
                                                steps.step2 && <>
                                                      <button type="button" className="btn btn-link btn-back" onClick={step2Back}>
                                                            <Icon icon="bi:arrow-left" color="#E18427" />
                                                            Back
                                                      </button>
                                                      <div className="deckformFields">
                                                            <label htmlFor="" className="Stepper-label">What is your email address? Promise, we don’t spam. 😉</label>
                                                            <FormGroup>
                                                                  <FormLabel className="mb-2">Your Email</FormLabel>
                                                                  <input type="email" placeholder="Example “johnsmith75@gmail.com”" value={state.email} className="form-control" id="email" name="email" onChange={eventhandler} />
                                                                  {errors.email && (<span className="text-danger small d-block"> * {errors.email} </span>)}
                                                            </FormGroup>

                                                            <button className="talk_btn d-block w-100 btn-next" type="submit" onClick={emailCheck}>
                                                                  <span className="pe-2">Next</span>
                                                                  <Icon icon="bi:arrow-right-circle" color="white" />
                                                            </button>
                                                      </div>
                                                </>
                                          }

                                          {/* Step 3 */}
                                          {
                                                steps.step3 && <>
                                                      <button type="button" className="btn btn-link btn-back" onClick={step3Back}>
                                                            <Icon icon="bi:arrow-left" color="#E18427" />
                                                            Back
                                                      </button>
                                                      <div className="deckformFields">
                                                            <label htmlFor="" className="Stepper-label">What’s your phone number? 🤝</label>
                                                            <FormGroup>
                                                                  <FormLabel className="mb-2">Phone Number</FormLabel>
                                                                  <div className="phoneNum">
                                                                        <div className="country-dropdown">
                                                                              <Dropdown>
                                                                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                                                                          {
                                                                                                selectedCountry ?
                                                                                                      <>
                                                                                                            {/* <i className="flags_img iso_AF"></i> + {selectedCountry.phonecode} */}
                                                                                                            <i className={'flags_img iso_' + selectedCountry.code}></i> + {selectedCountry.phonecode}
                                                                                                      </>
                                                                                                      : "Country"
                                                                                          }
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu as={CustomMenu}>
                                                                                          {
                                                                                                countryData.map((v, i) => {
                                                                                                      return (
                                                                                                            <Dropdown.Item onClick={() => selectedValue(v)} key={i}><i className={'flags_img iso_' + v.code}></i> <span>+ {v.phonecode}</span> {v.name}</Dropdown.Item>)
                                                                                                })
                                                                                          }
                                                                                          {/* {
                                                                                                countryData.map((v, i) => {
                                                                                                      return (<>
                                                                                                            <Dropdown.Item onClick={() => selectedValue(v)} key={i}><i className={'flags_img iso_' + v.code}></i> <span>+ {v.phonecode}</span> {v.name}</Dropdown.Item>
                                                                                                            {i == 3&&
                                                                                                            <Dropdown.Divider />}
                                                                                                            </>)
                                                                                                })
                                                                                          } */}
                                                                                    </Dropdown.Menu>
                                                                              </Dropdown>
                                                                        </div>
                                                                        {/* <select className="form-control" id="country" name="country" value={state.country} onChange={eventhandler}>
                                                                              {
                                                                                    countryData.map((v) => {
                                                                                          return (
                                                                                                <option value={v.phonecode} key={v.id}>+{v.phonecode} {v.name}</option>
                                                                                          )
                                                                                    })
                                                                              }
                                                                        </select> */}
                                                                        {/* {errors.phone && (<span className="text-danger small d-block"> * {errors.phone} </span>)} */}

                                                                        {/* <ReactInputMask mask="999 999 9999" className="form-control" value={state.phone} onChange={eventhandler} maskChar={" "} placeholder="Phone Number * " name="phone" id="phone" /> */}
                                                                        <input className="form-control" minLength="9" maxLength="10" value={state.phone} onChange={eventhandler} placeholder="Phone Number * " name="phone" id="phone" />
                                                                        {errors.phone && (<span className="text-danger small d-block"> * {errors.phone} </span>)}
                                                                        <button className="talk_btn d-block w-100 btn-next" type="submit" onClick={phoneCheck}>
                                                                              <span className="pe-2">Next</span>
                                                                              <Icon icon="bi:arrow-right-circle" color="white" />
                                                                        </button>
                                                                  </div>
                                                            </FormGroup>
                                                      </div>
                                                </>
                                          }

                                          {/* Step 4 */}
                                          {
                                                steps.step4 && <>
                                                      <button type="button" className="btn btn-link btn-back" onClick={step4Back}>
                                                            <Icon icon="bi:arrow-left" color="#E18427" />
                                                            Back
                                                      </button>
                                                      <div className="deckformFields">
                                                            <label htmlFor="" className="Stepper-label">What are you looking for?</label>
                                                            <div className="radio-container">
                                                                  <div className="form-radio">
                                                                        <input type="radio" id="bp" name="industry" onChange={eventhandler} checked={state.industry === "Business plan"} value="Business plan" />
                                                                        <label className="btn btn-default" htmlFor="bp">Business plan</label>
                                                                  </div>
                                                                  <div className="form-radio">
                                                                        <input type="radio" id="pd" name="industry" onChange={eventhandler} checked={state.industry === "Pitch Deck"} value="Pitch Deck" />
                                                                        <label className="btn btn-default" htmlFor="pd">Pitch Deck</label>
                                                                  </div>
                                                                  <div className="form-radio">
                                                                        <input type="radio" id="both" name="industry" onChange={eventhandler} checked={state.industry === "Both"} value="Both" />
                                                                        <label className="btn btn-default" htmlFor="both">Both</label>
                                                                  </div>
                                                                  <div className="form-radio">
                                                                        <input type="radio" id="other" name="industry" onChange={eventhandler} checked={state.industry === "Other"} value="Other" />
                                                                        <label className="btn btn-default" htmlFor="other">Other</label>
                                                                  </div>
                                                            </div>
                                                            {errors.industry && (<span className="text-danger small d-block"> * {errors.industry} </span>)}

                                                            <button className="talk_btn d-block w-100 btn-next" type="submit" onClick={industryCheck}>
                                                                  <span className="pe-2">Next</span>
                                                                  <Icon icon="bi:arrow-right-circle" color="white" />
                                                            </button>
                                                      </div>
                                                </>
                                          }

                                          {/* Step 5 */}
                                          {
                                                steps.step5 && <>
                                                      <button type="button" className="btn btn-link btn-back" onClick={step5Back}>
                                                            <Icon icon="bi:arrow-left" color="#E18427" />
                                                            Back
                                                      </button>
                                                      <div className="deckformFields">
                                                            <label htmlFor="" className="text-center d-block fw-normal mb-4">You are doing great 👏🏻</label>
                                                            <label htmlFor="" className="Stepper-label">What is your industry?</label>
                                                            <div className="radio-container">
                                                                  <div className="form-radio">
                                                                        <input type="radio" id="tech" name="service" onChange={eventhandler} checked={state.service === "Tech"} value="Tech" />
                                                                        <label className="btn btn-default" htmlFor="tech">Tech</label>
                                                                  </div>
                                                                  <div className="form-radio">
                                                                        <input type="radio" id="traditional" name="service" onChange={eventhandler} checked={state.service === "Traditional"} value="Traditional" />
                                                                        <label className="btn btn-default" htmlFor="traditional">Traditional</label>
                                                                  </div>
                                                                  <div className="form-radio">
                                                                        <input type="radio" id="other" name="service" onChange={eventhandler} checked={state.service === "Other"} value="Other" />
                                                                        <label className="btn btn-default" htmlFor="other">Other</label>
                                                                  </div>
                                                            </div>
                                                            {errors.service && (<span className="text-danger small d-block mt-3 text-center"> * {errors.service} </span>)}
                                                            <button className="talk_btn d-block w-100 btn-next" type="submit" onClick={serviceCheck}>
                                                                  <span className="pe-2">Next</span>
                                                                  <Icon icon="bi:arrow-right-circle" color="white" />
                                                            </button>
                                                      </div>
                                                </>
                                          }

                                          {/* Step 6 */}
                                          {
                                                steps.step6 && <>
                                                      <button type="button" className="btn btn-link btn-back" onClick={step6Back}>
                                                            <Icon icon="bi:arrow-left" color="#E18427" />
                                                            Back
                                                      </button>
                                                      <div className="deckformFields">
                                                            <label htmlFor="" className="text-center d-block fw-normal mb-4">We’re almost there 🙌🏻</label>
                                                            <label htmlFor="" className="Stepper-label">Any more information, notes you want to add?</label>
                                                            <textarea className="form-control moreInfo" value={state.more_info} name="more_info" id="more_info" onChange={eventhandler} rows="5" placeholder="Write more information here ..."></textarea>
                                                            {errors.more_info && (<span className="text-danger small d-block"> * {errors.more_info} </span>)}
                                                            <div className="d-flex">
                                                                  <button className="talk_btn d-block w-100 btn-skip" type="button" onClick={moreSkip}>
                                                                        {
                                                                              <span className="pe-2">Skip</span>
                                                                        }
                                                                  </button>
                                                                  <button className="talk_btn d-block w-100 btn-next" type="submit" disabled={spinner} onClick={MoreInfoCheck}>
                                                                        {
                                                                              spinner ?
                                                                                    <Spinner
                                                                                          as="span"
                                                                                          animation="border"
                                                                                          role="status"
                                                                                          aria-hidden="true"
                                                                                    /> : <>
                                                                                          <span className="pe-2">Submit</span>
                                                                                          <Icon icon="bi:arrow-right-circle" color="white" />
                                                                                    </>
                                                                        }
                                                                  </button>
                                                            </div>
                                                      </div>
                                                </>
                                          }

                                          {/* Step 7 */}
                                          {
                                                steps.step7 && <>
                                                      {/* <button type="button" className="btn btn-link btn-back" onClick={step7Back}>
                                                            <Icon icon="bi:arrow-left" color="#E18427" />
                                                            Back
                                                      </button> */}
                                                      <div className="deckformFields">
                                                            <label htmlFor="" className="text-center d-block fw-normal mb-4">Last one 🎉 </label>
                                                            <label htmlFor="" className="Stepper-label">Verify +{countryCode} {state.phone}, with the four digit code you just got! 🙏🏻 </label>
                                                            <div className="otpContainer">
                                                                  <OTPInput value={OTP} onChange={setOTP} className={incorrectOTP && 'errorOtp'} autoFocus OTPLength={4} otpType="number" disabled={false} />
                                                            </div>
                                                            {/* <ResendOTP onResendClick={() => console.log("Resend clicked")} /> */}
                                                            {
                                                                  !incorrectOTP ?
                                                                        timer ?
                                                                              <button className="resendOtp">
                                                                                    Resend OTP in <span>({timer} sec)</span>
                                                                              </button> : <button className="resendOtp" onClick={resetTimer} disabled={timer}>
                                                                                    <span>Resend OTP </span>
                                                                              </button> :
                                                                        <button className="resendOtp error" type="button" onClick={sendCodeAgain}>
                                                                              You have entered invalid code <span className="text-dark fw-bold"><u>Send code again</u></span>
                                                                        </button>

                                                            }
                                                            <button className="talk_btn d-block w-100 btn-next" type="button" onClick={verifyOtp}>
                                                                  <span className="pe-2">Verify my Phone</span>
                                                                  <Icon icon="bi:arrow-right-circle" color="white" />
                                                            </button>
                                                      </div>
                                                </>
                                          }
                                    </form>
                                    <div className="steps">
                                          <ProgressBar now={now} label={`${now}%`} visuallyHidden />
                                          <span className="ms-4 d-inline-block">{now}%</span>
                                    </div>
                                    <label className="text-center d-block text-center mt-4"> <img src={imageurl.check} className="img-fluid me-2" alt="check ico" /> <span className="fw-bold numscroll-block"><NumberScroller to={number} timeout={1000} /></span> People filled this form.</label>
                              </div>
                        </Container >
                  </> : <>
                        <Container className="mb-5 mt-0">
                              <div className="mx-auto">
                                    <div className="mb-4 text-center form-landing pitch-text">
                                          <h5 className="mb-4" >
                                                <p style={{ color: "#E18427" }} className="mb-0 d-inline">Ready</p>
                                                <p className="mb-0 d-inline"> to share your vision with us?</p>
                                          </h5>
                                          <p>Answer simple questions to help us evaluate,<br /> what’s the best package for you.</p>
                                    </div>

                                    {/* <button className="talk_btn d-block w-100 btn-next" type="button" onClick={setShowForm(true)}> */}
                                    <button className="talk_btn d-block w-100 btn-next" type="button" onClick={changeFormState}>
                                          <span className="pe-2">Let’s Get Started</span>
                                          <Icon icon="bi:arrow-right-circle" color="white" />
                                    </button>
                              </div>
                              {/* <label className="text-center d-block text-center mt-4"> <img src={imageurl.check} className="img-fluid me-2" alt="check ico" /> <span className="fw-bold">{leadCount}</span> People filled this form.</label> */}
                              <label className="text-center d-block text-center mt-4"> <img src={imageurl.check} className="img-fluid me-2" alt="check ico" /> <span className="fw-bold numscroll-block"><NumberScroller decimalPlaces={2} renderFrequency={25} from={100} to={number} timeout={500} /></span> People filled this form.</label>
                        </Container>
                  </>
            }
      </div>
      )
}
const mapStateToProp = state => ({
      countryData: state.countries,
      leadCount: state.leads,
      pitchdeckSubmitForm: state.pitchdeckSubmitForm,
      resSendOtp: state.sendOtp,
      verifyOtpRes: state.verifyOtp
})
const mapDispatchToProps = dispatch => ({
      getCountries: () => dispatch(getCountry()),
      getleads: () => dispatch(countLead()),
      submitForm: payload => dispatch(submitForm(payload)),
      sendOtp: payload => dispatch(sendOtp(payload)),
      verifyOtpData: payload => dispatch(verifyOtpAction(payload)),
})
export default connect(mapStateToProp, mapDispatchToProps)(withRouter(PitchDeckForm));

// 466