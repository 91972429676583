export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const INITIALIZE_LOADER = "INITIALIZE_LOADER";
export const RESET_LOADING = "RESET_LOADING";
export const INITIALIZE_FORM = "INITIALIZE_FORM";
export const FORM_SUCCESS = "FORM_SUCCESS";
export const FORM_SUBMITTING = "FORM_SUBMITTING";
export const RESET_FORM_SUBMITTING = "RESET_FORM_SUBMITTING";

export const FETCH_BLOG_LIST = "FETCH_BLOG_LIST";
export const SET_BLOG_LIST = "SET_BLOG_LIST";
export const FETCH_BLOG_ERROR = "FETCH_BLOG_ERROR";
export const SET_BLOG_DETAIL = "SET_BLOG_DETAIL";
export const RESET_BLOG_DETAIL = "RESET_BLOG_DETAIL";
export const FETCH_PORTFOLIO_LIST = "FETCH_PORTFOLIO_LIST";
export const SET_PORTFOLIO_LIST = "SET_PORTFOLIO_LIST";
export const FETCH_PORTFOLIO_ERROR = "FETCH_PORTFOLIO_ERROR";
export const SET_PORTFOLIO_DETAIL = "SET_PORTFOLIO_DETAIL";
export const RESET_PORTFOLIO_DETAIL = "RESET_PORTFOLIO_DETAIL";
export const FETCH_PARTNERSHIP_ERROR = "FETCH_PARTNERSHIP_ERROR";
export const FETCH_PARTNERSHIP_LIST = "FETCH_PARTNERSHIP_LIST";
export const SET_PARTNERSHIP_LIST = "SET_PARTNERSHIP_LIST";

export const FETCH_EVENT_LIST = "FETCH_EVENT_LIST";
export const SET_EVENT_LIST = "SET_EVENT_LIST";
export const FETCH_EVENT_ERROR = "FETCH_EVENT_ERROR";
export const SET_EVENT_DETAIL = "SET_EVENT_DETAIL";
export const RESET_EVENT_DETAIL = "RESET_EVENT_DETAIL";

export const FETCH_HOME_LIST = "FETCH_HOME_LIST";
export const SET_HOME_LIST = "SET_HOME_LIST";
export const FETCH_HOME_ERROR = "FETCH_HOME_ERROR";


export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_LOADING = "GET_LOADING";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const ERR_COUNTRIES = "ERR_COUNTRIES";
export const DEF = "DEF";

export const LOAD_LEAD = "LOAD_LEAD";
export const GET_LEAD = "GET_LEAD";
export const SET_LEAD = "SET_LEAD";
export const ERR_LEAD = "ERR_LEAD";

export const SENDOTP_SUBMIT = "SENDOTP_SUBMIT";
export const SENDOTP_ERROR = "SENDOTP_ERROR";
export const SENDOTP_SUCCESS = "SENDOTP_SUCCESS";
export const RESET_SENDOTP_SUBMIT = "RESET_SENDOTP_SUBMIT";

export const PITCHDECK_INIT = "PITCHDECK_INIT";
export const PITCHDECK_SUBMIT = "PITCHDECK_SUBMIT";
export const PITCHDECK_SUCCESS = "PITCHDECK_SUCCESS";
export const RESET_PITCHDECK_SUBMIT = "RESET_PITCHDECK_SUBMIT";

export const OTPVERIFY_INIT = "OTPVERIFY_INIT";
export const OTPVERIFY_SUBMIT = "OTPVERIFY_SUBMIT";
export const OTPVERIFY_SUCCESS = "OTPVERIFY_SUCCESS";
export const RESET_OTPVERIFY_SUBMIT = "RESET_OTPVERIFY_SUBMIT";
export const OTPVERIFY_ERROR = "RESET_OTPVERIFY_SUBMIT";

export const NEWSLETTER_SUBMIT = "NEWSLETTER_SUBMIT";
export const NEWSLETTER_ERROR = "NEWSLETTER_ERROR";
export const NEWSLETTER_SUCCESS = "NEWSLETTER_SUCCESS";

export const BOOTCAMP_INIT = "BOOTCAMP_INIT";
export const BOOTCAMP_SUBMIT = "BOOTCAMP_SUBMIT";
export const BOOTCAMP_SUCCESS = "BOOTCAMP_SUCCESS";
export const RESET_BOOTCAMP_SUBMIT = "RESET_BOOTCAMP_SUBMIT";

export const FETCH_NEWSPR_LIST = "FETCH_BLOG_LIST";
export const SET_NEWSPR_LIST = "SET_BLOG_LIST";
export const FETCH_NEWSPR_ERROR = "FETCH_BLOG_ERROR";


export const FETCH_INSTAGRAM = "FETCH_INSTAGRAM";
export const SET_INSTAGRAM = "SET_INSTAGRAM";
export const FETCH_INSTAGRAM_ERROR = "FETCH_INSTAGRAM_ERROR";

export const FETCH_TIKTOK = "FETCH_TIKTOK";
export const SET_TIKTOK = "SET_TIKTOK";
export const FETCH_TIKTOK_ERROR = "FETCH_TIKTOK_ERROR";

export const FETCH_YOUTUBE = "FETCH_YOUTUBE";
export const SET_YOUTUBE = "SET_YOUTUBE";
export const FETCH_YOUTUBE_ERROR = "FETCH_YOUTUBE_ERROR";

export const FETCH_GUIDE = "FETCH_GUIDE";
export const SET_GUIDE = "SET_GUIDE";
export const FETCH_GUIDE_ERROR = "FETCH_GUIDE_ERROR";

export const LATESTALLPODCAST_LIST = "LATESTALLPODCAST_LIST";
export const FETCH_LATESTALLPODCAST_LIST = "FETCH_LATESTALLPODCAST_LIST";
export const SET_LATESTALLPODCAST_LIST = "SET_LATESTALLPODCAST_LIST";
export const FETCH_LATESTALLPODCAST_LIST_ERROR = "FETCH_LATESTALLPODCAST_LIST_ERROR";


export const SET_CHAPTER_GUIDE = "SET_CHAPTER_GUIDE";
export const RESET_CHAPTER_GUIDE = "RESET_CHAPTER_GUIDE";

export const SET_CHAPTER_DETAIL = "SET_CHAPTER_DETAIL";
export const RESET_CHAPTER_DETAIL = "RESET_CHAPTER_DETAIL";


export const LATEST_EPISODE = "LATEST_EPISODE";
export const FETCH_LATEST_PODCAST = "FETCH_LATEST_PODCAST";
export const SET_LATEST_PODCAST = "SET_LATEST_PODCAST";
export const FETCH_LATEST_PODCAST_ERROR = "FETCH_LATEST_PODCAST_ERROR";

export const FETCH_INFLUENCER = "FETCH_INFLUENCER";
export const SET_INFLUENCER = "SET_INFLUENCER";
export const FETCH_INFLUENCER_ERROR = "FETCH_INFLUENCER_ERROR";
export const RESET_INFLUENCER = "RESET_INFLUENCER";

export const SET_PODCAST_DETAIL = "SET_PODCAST_DETAIL";
export const FETCH_PODCAST_DETAIL = "FETCH_PODCAST_DETAIL";

export const FETCH_PODCAST_DETAIL_ERROR = "FETCH_PODCAST_DETAIL_ERROR";
export const RESET_PODCAST_DETAIL = "RESET_PODCAST_DETAIL"

export const CLICK_COUNTED = "CLICK_COUNTED";


