import { Icon } from "@iconify/react";
import React from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useParams, withRouter } from "react-router-dom";
import { getChapterList } from "../../store/actions/commonAction";
import { RESET_CHAPTER_GUIDE } from "../../store/common/types";
import imageurl from "../common/images";
import { Footer } from "../Landingpage/Footer";
import { Header } from "../Landingpage/Header";
import { MobileHeader } from "../Landingpage/Mobile/MobileHeader";
import SiteLoader from "../SiteLoader/SiteLoader";

const GuideChapter = () => {
      const { name } = useParams();
      const { loading, chapter } = useSelector(
            (state) => state.guide
      );
      const chapterList = chapter?.chapters;
      const dispatch = useDispatch();
      useEffect(() => {
            dispatch(getChapterList(name));
            return () => dispatch({ type: RESET_CHAPTER_GUIDE });
      }, [name, dispatch]);
      useEffect(() => {
            window.scroll(0, 0);
      }, []);

      return (
            <>
                  {loading ? <SiteLoader /> : null}
                  <Header />
                  <MobileHeader />
                  {/* Begin:: Chapters banner */}
                  <div className="hero_banner common-banner chapter-banner" >
                        <Container>
                              <Link to="/guides" className="btn btn-link btn-back fw-normal position-static d-none d-lg-block">
                                    <Icon icon="bi:arrow-left" color="#E18427" />
                                    Back
                              </Link>
                              <div className="ser_vi text-center mt-5 justify-content-center">
                                    <div className="mb-5">
                                          {/* <h5>The Always Updated <span>Email Marketing</span> Guide
                                                That’ll Make You More Money</h5> */}
                                          <h5>{chapter.title}</h5>
                                          <p className="title-para mb-5">From fundamentals to advance strategies, learn to build a quality subscriber list,<br className="d-none d-md-block" />
                                                drive more traffic, and increase revenue just like the email marketing pros.</p>
                                          <div className="deck_btns text-center">
                                                <button className="talk_btn btn-register bg-orange d-inline-block">
                                                      <span className="pe-2">Get Your Free Marketing Plan</span><Icon icon="bi:arrow-right-circle" color="white" />
                                                </button>
                                          </div>
                                    </div>
                              </div>
                        </Container>
                  </div>
                  {/* End:: Chapters banner */}

                  {/* Begin:: Chapters list */}
                  <Container fluid className="px-0">
                        {
                              chapterList &&
                                    chapterList.length > 0 ?
                                    <ul className="chapter-listing">
                                          {
                                                chapterList?.map((chapter, i) => {
                                                      return (
                                                            <li key={"chapter" + i}>
                                                                  <Container className="">
                                                                        <span className="chapter-no">Chapter {i + 1} :</span>
                                                                        <h2><Link to={`/guides/${name}/${chapter.id}`} className="text-dark">{chapter.title}</Link></h2>
                                                                        <h5>{chapter.description}</h5>

                                                                        <Row className="topic-listing col-lg-8 mx-auto align-items-center gx-lg-5">
                                                                              <Col md={6} className="text-center">
                                                                                    <img src={chapter.chapter_image_url} className="chapter-img" alt="chapter" />
                                                                              </Col>
                                                                              <Col md={6}>{
                                                                                    chapter.resources.length > 0 && <>
                                                                                          <h4 className="mb-4 fw-bold">Resources</h4>
                                                                                          <ul>
                                                                                                {
                                                                                                      chapter.resources.map((resource, i) => {
                                                                                                            return <li key={"resource" + i}><Link to={`/guides/${name}/${chapter.id}`}>{resource.title}</Link></li>
                                                                                                      })
                                                                                                }
                                                                                          </ul>
                                                                                    </>
                                                                              }
                                                                              </Col>
                                                                        </Row>
                                                                  </Container>
                                                            </li>
                                                      )
                                                })
                                          }
                                    </ul> :
                                    <div className="empty-post my-5">No Chapter Added</div>
                        }
                  </Container>
                  {/* End:: Chapters list */}

                  <Footer />
            </>
      )
}
// const mapDispatchToProp = dispatch => {
//       getChapter: () => dispatch(getGuide());
// }

// export default connect(null, mapDispatchToProp)(withRouter(GuideChapter));
export default GuideChapter;