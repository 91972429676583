import { toast } from "react-toastify";
import { bootcampSubmit } from "../api";
import AXIOS from "../common/api_helper";
import { BOOTCAMP_INIT, BOOTCAMP_SUCCESS, RESET_BOOTCAMP_SUBMIT } from "../common/types";

export const submitBootcamp = (payload, resetForm) => async(dispatch) => {
      try {
          dispatch({
              type: BOOTCAMP_INIT,
          });
          const { data } = await AXIOS.post(bootcampSubmit, {...payload });
          dispatch({
              type: BOOTCAMP_SUCCESS,
          });
      //     dispatch({
      //         type: RESET_BOOTCAMP_SUBMIT,
      //     });
          if (data.status) {
              resetForm();
              toast.success(data.message);
          } else {
              toast.error(data.message);
          }
      } catch (err) {
      //     dispatch({
      //         type: RESET_BOOTCAMP_SUBMIT,
      //     });  
      //     toast.error(err.message)
      }
  }
