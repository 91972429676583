import { Icon } from "@iconify/react";
import React from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getInstaPost, getTiktokPost, getYoutubePost } from "../../store/actions/commonAction";
import imageurl from "../common/images";
import { Footer } from "../Landingpage/Footer";
import { Header2 } from "../Landingpage/Header2";
import { MobileHeader2 } from "../Landingpage/Mobile/MobileHeader2";
import SiteLoader from "../SiteLoader/SiteLoader";

export const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
      const { carouselState: { currentSlide } } = rest;
      return (
            <div className="carousel-button-group_event slider-navs">
            <button className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()} ><Icon icon="bi-arrow-left-short" /></button>
            <button onClick={() => next()} >  <Icon icon="bi-arrow-right-short" /></button>
            {/* <button onClick={() => next()} >  <img src={imageurl.left} alt="" /></button> */}
            {/* <Button style={{background:"red",position:"absolute",left:"0" ,bottom:0}} onClick={() => goToSlide(currentSlide + 1)}> Go to any slide </Button> */}
      </div>
      );
};
const Social = ({ instagram, instaData, tiktok, tiktokData, youtube, youtubeData }) => {
      const responsive = {
            superLargeDesktop: {
                  // the naming can be any, depends on you.
                  breakpoint: { max: 4000, min: 3000 },
                  items: 5,
                  partialVisibilityGutter: 40

            },
            desktop: {
                  breakpoint: { max: 3000, min: 1200 },
                  items: 3,
                  partialVisibilityGutter: 15,
                  centerMode: true,
            },
            tablet: {
                  breakpoint: { max: 1199, min: 767 },
                  items: 2,
                  partialVisibilityGutter: 15
            },
            mobile: {
                  breakpoint: { max: 767, min: 0 },
                  items: 1,
                  partialVisibilityGutter: 20
            },
      };
      const tiktokRes = {
            superLargeDesktop: {
                  // the naming can be any, depends on you.
                  breakpoint: { max: 4000, min: 3000 },
                  items: 3,
                  partialVisibilityGutter: 40

            },
            desktop: {
                  breakpoint: { max: 3000, min: 992 },
                  items: 3,
                  partialVisibilityGutter: 15
            },
            tablet: {
                  breakpoint: { max: 991, min: 767 },
                  items: 2,
                  partialVisibilityGutter: 15
            },
            mobile: {
                  breakpoint: { max: 767, min: 0 },
                  items: 1,
                  partialVisibilityGutter: 15
            },
      };
      const youtubeRes = {
            superLargeDesktop: {
                  // the naming can be any, depends on you.
                  breakpoint: { max: 4000, min: 3000 },
                  items: 3,
                  partialVisibilityGutter: 40

            },
            desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 2,
                  partialVisibilityGutter: 15
            },
            tablet: {
                  breakpoint: { max: 1024, min: 767 },
                  items: 2,
                  partialVisibilityGutter: 15
            },
            mobile: {
                  breakpoint: { max: 767, min: 0 },
                  items: 1,
                  partialVisibilityGutter: 15
            },
      };

      useEffect(() => {
            instagram();
            tiktok();
            youtube();
      }, [])

      const instaList = instaData.instagram;
      const tiktokList = tiktokData.tiktok;
      const youtubeList = youtubeData.youtube;

      return (<>
            <Header2 />
            <MobileHeader2 />
            <div className="hero_banner social-banner bg-green social-banner" >
                  <Container className="position-relative1">
                        <img className="head-star" src={imageurl.founder_highlight} alt="ico" />
                        <Row>
                              <Col lg={6}>
                                    <div className="ser_vi">
                                          <div className="mb-5">
                                                <h5 className="text-white ">
                                                      We are just As
                                                      <span> Social</span>, As We Are Global.
                                                </h5>
                                                <p className="title-para mb-4 text-white">
                                                      An Award Winning Venture Ecosystem Supporting Startup Entrepreneurs With A Formulaic & Holistic Structure.
                                                </p>
                                                <div className="deck_btns">
                                                      <Link className="talk_btn btn-register bg-orange d-inline-block" to="/startaproject">
                                                            <span className="pe-2">Start a Project</span><Icon icon="bi:arrow-right-circle" color="white" />
                                                      </Link>
                                                </div>
                                          </div>
                                    </div>
                              </Col>
                              <Col lg={6}>
                                    <div className="business_img">
                                          <img src={imageurl.socialHero} className="social-hero" alt="Social hero" />
                                    </div>
                              </Col>
                        </Row>
                  </Container>
            </div>

            {/* Begin:: Instagram post */}
            <Container fluid className="instagram-post position-relative px-0">
                  <Container>
                        <div className="ser_vi text-center text-md-start">
                              <h5 className="title_track new_common mb-0 fw-bold">
                                    <b>See Us On <span>Instagrm</span></b>
                              </h5>
                        </div>
                  </Container>
                  {instaData.loading && instaData.loading && instaData.loading ? <SiteLoader /> : null}
                  <div className="instPost-slider">
                        {
                              instaList &&
                                    instaList.length > 0 ?
                                    <Carousel responsive={responsive} centerMode infinite={true} renderButtonGroupOutside={true} arrows={false} customButtonGroup={<ButtonGroup />}>
                                          {
                                                instaList.map((instagram, i) => {
                                                      return (
                                                            <div>
                                                                  <div className="social-list">
                                                                        <img src={instagram.thumbnail} className="img-fluid" alt="social-image" />
                                                                  </div>
                                                                  <div className="px-3">
                                                                        <h6>{instagram.post_title}</h6>
                                                                        <p className="text-orange"><span>#instagram</span>
                                                                              <span>#trend</span>
                                                                              <span>#marketing</span>
                                                                              <span>#convrtx</span>
                                                                              <span>#ads</span>
                                                                        </p>
                                                                  </div>
                                                            </div>
                                                      )
                                                })
                                          }
                                    </Carousel> :
                                    <div className="empty-post">No post found</div>
                        }

                  </div>
                  <div className="text-center">
                        <button className="talk_btn btn-register">
                              <span className="pe-2">View in Instagram</span><Icon icon="bi:arrow-right-circle" color="white" />
                        </button>
                  </div>
            </Container>
            {/* End:: Instagram post */}

            {/* Begin:: Tiktok */}
            <Container fluid className="tiktok-post">
                  <Container className="position-relative">
                        <div className="ser_vi text-center text-md-start">
                              <h5 className="title_track new_common mb-0 fw-bold">
                                    <b>See Us On <span>TikTok</span></b>
                              </h5>
                        </div>
                        <div className="tiktok-slider">
                              {
                                    tiktokList &&
                                          tiktokList.length > 0 ?
                                          <Carousel responsive={tiktokRes} infinite={true} renderButtonGroupOutside={true} arrows={false} customButtonGroup={<ButtonGroup />}>
                                                {
                                                      instaList.map((tiktokList, i) => {
                                                            return (
                                                                  <div key={"tiktok"+ i}>
                                                                        <div className="tiktok-list">
                                                                              <img src={tiktokList.thumbnail} className="img-fluid" alt="social-image" />
                                                                        </div>
                                                                  </div>
                                                            )
                                                      })
                                                }
                                          </Carousel> :
                                          <div className="empty-post">No post found</div>
                              }

                        </div>
                        <div className="text-center">
                              <button className="talk_btn btn-register">
                                    <span className="pe-2">View in Tiktok</span><Icon icon="bi:arrow-right-circle" color="white" />
                              </button>
                        </div>
                  </Container>
            </Container>
            {/* End:: Tiktok */}

            {/* Begin:: Youtube */}
            <Container fluid className="youtube-post">
                  <Container className="position-relative">
                        <div className="ser_vi text-center text-md-start">
                              <h5 className="title_track new_common mb-0 fw-bold">
                                    <b>See Us On <span>Youtube</span></b>
                              </h5>
                        </div>
                        <div className="youtube-slider">

                              {
                                    youtubeList &&
                                          youtubeList.length > 0 ?
                                          <Carousel responsive={youtubeRes} infinite={true} renderButtonGroupOutside={true} arrows={false} customButtonGroup={<ButtonGroup />}>
                                                {
                                                      youtubeList.map((youtube, i) => {
                                                            return (
                                                                  <a href={youtube.post_link} className="d-block" target="_blank" key={"Youtube" + i}>
                                                                        <div className="youtube-list">
                                                                              <img src={youtube.thumbnail} className="img-fluid" alt="social-image" />
                                                                              <span className="play-btn"><Icon icon="fa:play" color="white" /></span>
                                                                              <span className="video-duration">2:45</span>
                                                                        </div>
                                                                        <div className="px-3">
                                                                              <h4 className="mb-3">{youtube.post_title}</h4>
                                                                              <div className="auther-info mt-0">
                                                                                    <img src={imageurl.mediaPoster} />
                                                                                    <p className="mb-0">Youtube</p>
                                                                                    <small className="px-2"><Icon icon="fa:circle" className="small" /></small>
                                                                                    {youtube.creted_at}
                                                                              </div>
                                                                        </div>
                                                                  </a>
                                                            )
                                                      })
                                                }
                                          </Carousel> :
                                          <div className="empty-post">No post found</div>
                              }
                        </div>
                        <div className="text-center">
                              <button className="talk_btn btn-register">
                                    <span className="pe-2">View in Youtube</span><Icon icon="bi:arrow-right-circle" color="white" />
                              </button>
                        </div>
                  </Container>
            </Container>
            {/* End:: Youtube */}

            <Footer />

      </>);
}

const mapStateToProp = (state) => ({
      instaData: state.instagram,
      tiktokData: state.tiktok,
      youtubeData: state.youtube,
})
const mapDispatchToprop = dispatch => ({
      instagram: () => dispatch(getInstaPost()),
      tiktok: () => dispatch(getTiktokPost()),
      youtube: () => dispatch(getYoutubePost()),
})

export default connect(mapStateToProp, mapDispatchToprop)(withRouter(Social));