import React, { useState } from "react";
import { Container, Navbar, Accordion, Nav } from "react-bootstrap";

import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import imageurl from "../common/images";

export const MobileHeader = () => {
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };
  const [show, setShow] = useState(false);
  window.addEventListener("scroll", changeColor);
  return (
    <div className="mobile_header">
      <Navbar
        className={color ? "header header_bg" : "header header-transparent"}
        expand="lg"
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img src={imageurl.logo} alt="" />
          </Navbar.Brand>
          <div className="partial">
          {/* <Link className="open_talk border-bottom-0 " to="/startaproject">
                <button className="talk_btn d-block w-100">
                  <span>Start a Project</span>{" "}
                  <Icon icon="bi:arrow-right-circle" color="white" />
                </button>
              </Link> */}
          <span onClick={() => setShow(true)}><img src={imageurl.mobileham} alt="" /></span>
          </div>

          <div className={show ? "menu_box show_one" : " menu_box show_two"} >
            <div style={{ textAlign: "right", marginBottom: "12px" }}>
              <span onClick={() => setShow(false)}><Icon icon="ep:close" color="white" width="30" height="30" vFlip={true} /></span>
            </div>
            <Nav.Link className="mb-0" as={Link} to="#aboutBootcamp" onClick={() => setShow(false)}>About Us</Nav.Link>
            <Nav.Link as={Link} to="/" onClick={() => setShow(false)}>Our Process</Nav.Link>
            <Nav.Link as={Link} to="/" onClick={() => setShow(false)}>Samples</Nav.Link>
            <Nav.Link as={Link} to="/" onClick={() => setShow(false)}>Packages</Nav.Link>
            <Nav.Link as={Link} to="#bootcampReview" onClick={() => setShow(false)}>Reviews</Nav.Link>
          <div className="open_gym">
          <Link className="open_talk border-bottom-0" to="/startaproject">
                <button className="talk_btn d-block w-100">
                  <span>Register</span>{" "}
                  <Icon icon="bi:arrow-right-circle" color="white" />
                </button>
              </Link>
          </div>
          </div>
        </Container>
      </Navbar>
    </div>
  );
};
