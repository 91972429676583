const INDUSTRIES = [
    "All",
    "FinTech",
    "Social Networking",
    "AI",
    "Web 3.0",
    "SaaS",
    "Health Tech",
    "B2B",
];
const INDUSTRY_OTPION = [

    "FinTech",
    "Social Networking",
    "AI",
    "Web 3.0",
    "SaaS",
    "Health Tech",
    "B2B",
];
export { INDUSTRIES, INDUSTRY_OTPION };