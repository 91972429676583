import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
import { Icon } from '@iconify/react';
import imageurl from '../common/images';
export const Fundarise = () => {
    return (
        <div className='fundarise py-0' >
            <Container className=' frustattion border-top-0'>
                <Row>
                    <Col lg={6} className="px-0 px-md-3">
                        <div className="fundarise_img">
                            <img src={imageurl.books} alt="" />
                            <small>
                                <div className="text_box">
                                    {/* <h5>Largest <br />fundraising <br /> network</h5> */}
                                    <h5>The Largest <br/>Startup <br/>Ecosystem</h5>
                                </div>
                            </small>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="fundarise_details">
                            <div className='saas-resp-ps'>
                                <h5 className='mb-0 mb-md-4'>We help you <span>Build SaaS Platforms </span>for Startups</h5>
                                <ul>
                                    <li>
                                        <Icon icon="akar-icons:circle-check-fill" />
                                        <span>Former VC/PE experts create your <br />custom business plan</span>
                                    </li>
                                    <li>
                                        <Icon icon="akar-icons:circle-check-fill" />
                                        <span>Tier 1 VC grade Silicon Valley business<br /> plan standards.</span>
                                    </li>
                                    <li>
                                        <Icon icon="akar-icons:circle-check-fill" />
                                        <span>Startups to Inc 5,000 and Fortune 50 <br />Companies - 1,000 + clients</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
