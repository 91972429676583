import { Icon } from "@iconify/react";
import { useHistory, withRouter } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Button, Container, Dropdown, Form, FormGroup, FormLabel, Modal, ProgressBar } from "react-bootstrap";
import NumberScroller from "react-number-scroller";
import { connect } from "react-redux";
import { getCountry } from "../../store/actions/pitchDeckAction";
import { submitBootcamp } from "../../store/actions/bootcampAction";

import imageurl from "../common/images";

const Register = (props) => {
      const { show, onClose } = props;
      const getcountry = props.getCountries;
      const history = useHistory()
      const countryData = props.countryData.countries

      useEffect(() => {
            getcountry()
      }, [])
      const [now, setNow] = useState(10);
      const [steps, setSteps] = useState({
            step1: true,
            step2: false,
            step3: false,
      })

      const step2Back = () => {
            setSteps((prevState) => ({
                  ...prevState,
                  step2: false,
                  step1: true
            }));
      }
      const step3Back = () => {
            setSteps((prevState) => ({
                  ...prevState,
                  step3: false,
                  step2: true
            }));
      }

      const [errors, setErrors] = useState({
            name: false,
            email: false,
            phone: false,
            country: false,
      });
      const [state, setState] = useState({
            name: "",
            email: "",
            phone: "",
            country: "231",
      });
      const [countryCode, setCountryCode] = useState(1);

      const eventhandler = (e) => {
            const { id, value, name } = e.target;
            const validEmailRegex = RegExp(
                  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            );
            setState((prevState) => ({
                  ...prevState,
                  [id]: value,
            }));
            switch (name) {
                  case "name":
                        if (value.length === 0) {
                              setErrors((prevState) => ({
                                    ...prevState,
                                    name: "Required",
                              }));
                        } else {
                              setErrors((prevState) => ({
                                    ...prevState,
                                    name: false,
                              }));
                              setState((prevState) => ({
                                    ...prevState,
                                    name: value.replace(/[^A-Za-z ]/ig, '')
                              }))
                        }
                        break;
                  case "email":
                        if (value.length === 0) {
                              setErrors((prevState) => ({
                                    ...prevState,
                                    email: "Required",
                              }));
                        } else if (!validEmailRegex.test(value)) {
                              setErrors((prevState) => ({
                                    ...prevState,
                                    email: "Invalid Email",
                              }));
                        } else {
                              setErrors((prevState) => ({
                                    ...prevState,
                                    email: false,
                              }));
                        }
                        break;
                  case "phone":
                        const result = value.replace(/\D/g, '');
                        setState((prevState => ({
                              ...prevState,
                              phone: result
                        })))

                        if (value.length === 0) {
                              setErrors((prevState) => ({
                                    ...prevState,
                                    phone: "Required",
                              }));
                        } else {
                              setErrors((prevState) => ({
                                    ...prevState,
                                    phone: false,
                              }));
                        }
                        break;
                  default:
            }
      };

      const nameCheck = () => {
            if (state.name === "") {
                  setErrors((prevState) => ({
                        ...prevState,
                        name: "Required",
                  }));
            } else {
                  setSteps((prevState) => ({
                        ...prevState,
                        step1: false,
                        step2: true
                  }))
                  setNow(30)
            }
      }
      const emailCheck = () => {
            const validEmailRegex = RegExp(
                  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            );
            if (state.email === "") {
                  setErrors((prevState) => ({
                        ...prevState,
                        email: "Required",
                  }));
            } else if (!validEmailRegex.test(state.email)) {
                  setErrors((prevState) => ({
                        ...prevState,
                        email: "Invalid Email ",
                  }));
            } else {
                  setSteps((prevState) => ({
                        ...prevState,
                        step2: false,
                        step3: true
                  }))
                  setNow(60)
            }
      }
      const phoneCheck = () => {
            if (state.phone === "") {
                  setErrors((prevState) => ({
                        ...prevState,
                        phone: "Required",
                  }));
            } else if (state.phone.replaceAll(/\s/g, '').length < 9) {
                  setErrors((prevState) => ({
                        ...prevState,
                        phone: "Too short.",
                  }));
            }
            else {
                  submitBootcamp()
                  setNow(90)
            }
      }

      const submitBootcamp = () => {
            props.bootcampSubmit({
                  full_name: state.name,
                  email: state.email,
                  country_code: state.country,
                  phone: state.phone
            });
            // history.push("/thankyou")
            // if(props.bootcampFormRes.success){
                  window.location.href = 'https://buy.stripe.com/cN2bMc5mb56S0pO28h';
            // }
      }

      const [selectedCountry, setSelectedCountry] = useState({
            phonecode: 1,
            code: "US"
      });
      const selectedValue = (v) => {
            setSelectedCountry(v);
            setState((prevState) => ({
                  ...prevState,
                  country: v.id,
            }))
            setCountryCode(v.phonecode)
            // setSelectedCountry();
      }

      const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
            <a
                  href=""
                  ref={ref}
                  onClick={(e) => {
                        e.preventDefault();
                        onClick(e);
                  }}
            >
                  {children}
            </a>
      ));
      // Dropdown needs access to the DOM of the Menu to measure it
      const CustomMenu = React.forwardRef(
            ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
                  const [value, setValue] = useState('');
                  // console.log("ref ", ref, children, className)

                  return (<>
                        <div
                              ref={ref}
                              style={style}
                              className={className}
                              aria-labelledby={labeledBy}
                        >

                              <div className="drpSearch">
                                    <Form.Control
                                          autoFocus
                                          className="my-3"
                                          placeholder="Search"
                                          onChange={(e) => setValue(e.target.value)}
                                          value={value}
                                    /></div>
                              <ul className="list-unstyled">
                                    {React.Children.toArray(children).filter(
                                          (child) =>
                                                // !value || child.props.children.toLowerCase().startsWith(value),
                                                // !value || child.props.children.toString().match(value),
                                                // console.log("Filter ", !value || child.props.children.toString().toUpperCase().match(value.toUpperCase()))
                                                !value || child.props.children.toString().toUpperCase().match(value.toUpperCase()),
                                    )}
                              </ul>
                        </div>
                  </>
                  );
            }
      );
      return (
            <Modal
                  show={show}
                  onHide={onClose}
                  backdrop="static"
                  keyboard={false}
                  size="xl"
            >
                  <Modal.Body>
                        <Container className="position-relative partnership_open leadForm my-5 pb-0 bootcamp-form">
                              <div className="box col-xl-8 mx-auto " >
                                    <img src={imageurl.badge} className="guarantee-badge" alt="money back guarantee" />
                                    <div className="mb-4 text-center pitch-text">
                                          <h5 className="mb-4">
                                                <span>Let’s Get Registered in </span><br />
                                                <p style={{ color: "#E18427" }} className="mb-0 d-inline">
                                                      {" "}Startup Bootcamp!
                                                </p>
                                          </h5>
                                          <p>Lets get started, Fill out the form below.</p>
                                    </div>
                                    <form onSubmit={e => { e.preventDefault() }}>
                                          {/* Step 1 */}
                                          {steps.step1 && <>
                                                <button type="button" className="btn btn-link btn-back" onClick={onClose}>
                                                      <Icon icon="bi:arrow-left" color="#E18427" />
                                                      Back
                                                </button>
                                                <div className="deckformFields">
                                                      <label htmlFor="" className="Stepper-label">Nice to meet you, what’s your name?</label>
                                                      <FormGroup>
                                                            <FormLabel className="mb-2">Your Name</FormLabel>
                                                            <input type="text" placeholder="Example “John Smith”" value={state.name} className="form-control" id="name" name="name" onChange={eventhandler} />
                                                            {errors.name && (<span className="text-danger small d-block"> * {errors.name} </span>)}
                                                      </FormGroup>

                                                      <button className="talk_btn d-block w-100 btn-next mt-4" type="submit" onClick={nameCheck}>
                                                            <span className="pe-2">Next</span>
                                                            <Icon icon="bi:arrow-right-circle" color="white" />
                                                      </button>
                                                </div>
                                          </>
                                          }

                                          {/* Step 2 */}
                                          {
                                                steps.step2 && <>
                                                      <button type="button" className="btn btn-link btn-back" onClick={step2Back}>
                                                            <Icon icon="bi:arrow-left" color="#E18427" />
                                                            Back
                                                      </button>
                                                      <div className="deckformFields">
                                                            <label htmlFor="" className="Stepper-label">What is your email address? Promise, we don’t spam. 😉</label>
                                                            <FormGroup>
                                                                  <FormLabel className="mb-2">Your Email</FormLabel>
                                                                  <input type="email" placeholder="Example “johnsmith75@gmail.com”" value={state.email} className="form-control" id="email" name="email" onChange={eventhandler} />
                                                                  {errors.email && (<span className="text-danger small d-block"> * {errors.email} </span>)}
                                                            </FormGroup>

                                                            <button className="talk_btn d-block w-100 btn-next mt-4" type="submit" onClick={emailCheck}>
                                                                  <span className="pe-2">Next</span>
                                                                  <Icon icon="bi:arrow-right-circle" color="white" />
                                                            </button>
                                                      </div>
                                                </>
                                          }

                                          {/* Step 3 */}
                                          {
                                                steps.step3 && <>
                                                      <button type="button" className="btn btn-link btn-back" onClick={step3Back}>
                                                            <Icon icon="bi:arrow-left" color="#E18427" />
                                                            Back
                                                      </button>
                                                      <div className="deckformFields">
                                                            <label htmlFor="" className="Stepper-label">What’s your phone number? 🤝</label>
                                                            <FormGroup>
                                                                  <FormLabel className="mb-2">Phone Number</FormLabel>
                                                                  <div className="phoneNum">
                                                                        <div className="country-dropdown">
                                                                              <Dropdown>
                                                                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                                                                          {
                                                                                                selectedCountry ?
                                                                                                      <>
                                                                                                            {/* <i className="flags_img iso_AF"></i> + {selectedCountry.phonecode} */}
                                                                                                            <i className={'flags_img iso_' + selectedCountry.code}></i> + {selectedCountry.phonecode}
                                                                                                      </>
                                                                                                      : "Country"
                                                                                          }
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu as={CustomMenu}>
                                                                                          {
                                                                                                countryData.map((v, i) => {
                                                                                                      return (
                                                                                                            <Dropdown.Item eventKey={v.id} onClick={() => selectedValue(v)} key={i}><i className={'flags_img iso_' + v.code}></i> <span>+ {v.phonecode}</span> {v.name}</Dropdown.Item>
                                                                                                      )
                                                                                                })
                                                                                          }
                                                                                    </Dropdown.Menu>
                                                                              </Dropdown>
                                                                        </div>

                                                                        {/* <ReactInputMask mask="999 999 9999" className="form-control" value={state.phone} onChange={eventhandler} maskChar={" "} placeholder="Phone Number * " name="phone" id="phone" /> */}
                                                                        <input className="form-control" minLength="9" maxLength="10" value={state.phone} onChange={eventhandler} placeholder="Phone Number * " name="phone" id="phone" />
                                                                        {errors.phone && (<span className="text-danger small d-block"> * {errors.phone} </span>)}
                                                                        <button className="talk_btn d-block w-100 btn-next mt-4" type="submit" onClick={phoneCheck}>
                                                                              <span className="pe-2">Proceed to Payment</span>
                                                                              <Icon icon="bi:arrow-right-circle" color="white" />
                                                                        </button>
                                                                  </div>
                                                            </FormGroup>
                                                      </div>
                                                </>
                                          }
                                    </form>
                                    <div className="steps">
                                          <ProgressBar now={now} label={`${now}%`} visuallyHidden />
                                          <span className="ms-4 d-inline-block">{now}%</span>
                                    </div>
                                    <label className="text-center d-block text-center mt-4"> <img src={imageurl.check} className="img-fluid me-2" alt="check ico" /> <span className="fw-bold numscroll-block"><NumberScroller to={1025} timeout={1000} /></span> Students already registered to this bootcamp.</label>
                              </div>
                        </Container >
                  </Modal.Body>
            </Modal>
      );
}
const mapStateToProp = state => ({
      countryData: state.countries,
      bootcampFormRes: state.bootcampSignup,
})
const mapDispatchToProps = dispatch => ({
      getCountries: () => dispatch(getCountry()),
      bootcampSubmit: payload => dispatch(submitBootcamp(payload)),
})
export default connect(mapStateToProp, mapDispatchToProps)(withRouter(Register));