import { Icon } from "@iconify/react";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import NumberScroller from "react-number-scroller";
import imageurl from "../common/images";
import { Features } from "../Landingpage/Features";
import { Review } from "../Landingpage/Review";

import { Header } from "./Header";
import { MobileHeader } from "./MobileHeader";
import { Link } from "react-router-dom";
import { Footer } from "../Landingpage/Footer";
import Industries from "./Industries";
import { AppStartups } from "./AppStartups";
import { StepsBusiness } from "./StepsBusiness";

const Mobile = () => {
      return (
            <>
                  <Header />
                  <MobileHeader />
                  <div className="hero_banner common-banner mobile-banner pb-0">
                        <Container className="position-relative">
                              <div className="ser_vi ">
                                    <div className="mb-md-5">
                                          <h5>
                                                The Worldwide Leader in <span>Mobile App Development</span>
                                          </h5>
                                    </div>
                              </div>
                              <Row className="gx-5">
                                    <Col lg={6} className="">
                                          <div className="business_img">
                                                <img src={imageurl.mobileHero} className="img-fluid mobile-hero" alt="Guide hero" />
                                          </div>
                                    </Col>
                                    <Col lg={6}>
                                          <div className="ser_vi ">
                                                <div className="mb-5 mobile-text">
                                                      <span className="arrow"></span>
                                                      <p className="title-para mb-5">ConvrtX is the global leader in Startup App Development. Over 10,000 startups worked with to-date. $4+ Billion in capital raised and counting.</p>
                                                      <div className="deck_btns pt-5">
                                                            <button className="talk_btn btn-register bg-orange">
                                                                  <span className="pe-2">Start a Project</span><Icon icon="bi:arrow-right-circle" color="white" />
                                                            </button>
                                                      </div>
                                                </div>
                                          </div>
                                    </Col>
                              </Row>
                        </Container>
                  </div>

                  <Container className="mb-5 mt-0 mobile-pitch-form">
                        <div className="col-xl-8 mx-auto">
                              <div className="mb-4 text-center form-landing pitch-text mt-0">
                                    <span className="form-shape"></span>
                                    <h5 className="mb-4" >
                                          <span> Need an </span>
                                          <span style={{ color: "#E18427" }} className="mb-0 d-inline">
                                                {" "}App <br /> Development
                                          </span>
                                          <span className="mb-0 d-inline">   for <br /> your Startup</span>
                                    </h5>
                                    <p className="form-info fw-semibold">Answer simple questions to help us evaluate,<br /> what’s the best package for you.</p>
                              </div>

                              {/* <button className="talk_btn d-block w-100 btn-next" type="button" onClick={setShowForm(true)}> */}
                              <button className="talk_btn d-block w-100 btn-next" type="button" >
                                    <span className="pe-2">Let’s Get Started</span>
                                    <Icon icon="bi:arrow-right-circle" color="white" />
                              </button>
                        </div>
                        {/* <label className="text-center d-block text-center mt-4"> <img src={imageurl.check} className="img-fluid me-2" alt="check ico" /> <span className="fw-bold">{leadCount}</span> People filled this form.</label> */}
                        <label className="text-center d-block text-center mt-4"> <img src={imageurl.check} className="img-fluid me-2" alt="check ico" /> <span className="fw-bold numscroll-block"><NumberScroller from={100} to={10597} timeout={0} /></span> People filled this form.</label>
                  </Container>
                  <div className="bg-f6">
                        <Features />
                  </div>
                  <AppStartups />
                  <Industries />
                  <StepsBusiness />
                  <Review />

                  <div className='business_pitch'>
                        <Container>
                              <div className="get_box">
                                    <Row>
                                          <Col>
                                                <h5>Wanna Launch an <span>App</span> <br />for your <span>Startup?</span></h5>
                                                <p>ConvrtX is a team of professional researchers, designers, and<br/> developers. Get the app for your startup.</p>
                                          </Col>
                                    </Row>
                                    <Row>
                                          <Col className="text-center"> <Link className="open_talk" to="/ContactUs">
                                                <Link className="open_talk" to="#startaproject">
                                                      <button className="talk_btn btn-register btn-arrow-size">
                                                            <span className="pe-2">Let’s Get Started</span>
                                                            <Icon icon="bi:arrow-right-circle" color="white" />
                                                      </button>
                                                </Link>
                                          </Link></Col>
                                    </Row>
                              </div>
                        </Container>
                  </div>

                  <Footer />
            </>
      )
}

export default Mobile;