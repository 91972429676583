import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { newsletter } from "../../store/actions/pitchDeckAction";
import imageurl from "../common/images";

const Newsletter = (props) => {
      const [show, setShow] = useState(false);
      const [isshown, setIsshown] = useState(true);
      const [email, setEmail] = useState("");
      const [emailErr, setEmailErr] = useState(false);

      const handleShow = () => setShow(true);
      const handleClose = () => {
            setShow(false)
            setIsshown(false);
      };
      const submitForm = () => {
            const validEmailRegex = RegExp(
                  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            );
            if (email === "") {
                  setEmailErr("Email is required");
            } else if (!validEmailRegex.test(email)) {
                  setEmailErr("Invalid Email");
            }
            else {
                  props.newsletter({ email: email })
                  // setIsshown(true)
                  setShow(false);
                  setIsshown(false);
                  fetch(imageurl.newsletterPDF).then(response => {
                        response.blob().then(blob => {
                            // Creating new object of PDF file
                            const fileURL = window.URL.createObjectURL(blob);
                            // Setting various property values
                            let alink = document.createElement('a');
                            alink.href = fileURL;
                            alink.download = 'Newsletter';
                            alink.click();
                        })
                    })
            }
      }

      useEffect(() => {
            document.addEventListener('scroll', function () {
                  if (document.documentElement.scrollTop > document.body.scrollHeight / 2) {
                        setShow(true);
                  }
            });
            setTimeout(function () {
                  setShow(true);
            }, 45000);
            // }, 2000);
      }, []);

      const eventhandler = (e) => {
            const { id, value, name } = e.target;
            const validEmailRegex = RegExp(
                  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            );
            setEmail(value)
            switch (name) {
                  case "email":
                        if (value.length === 0) {
                              setEmailErr("Required");
                        } else if (!validEmailRegex.test(value)) {
                              setEmailErr("Invalid Email");
                        } else {
                              setEmailErr(false);
                        }
                        break;
                  default:
            }
      };
      return (
            <>
                  {
                        isshown &&
                        <Modal show={show} onHide={handleClose} centered backdrop="static" size="lg" keyboard={false} className="newsletter-popup" >
                              <Modal.Header closeButton className="border-bottom-0">
                                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                              </Modal.Header>
                              <Modal.Body>
                                    <span className="newsletter-ribbon"><span>Rated # 1 By Investors 2022</span></span>
                                    <span className="newsletter-arrow"></span>
                                    <h2 className="newsletter-heading">Get Access To <br /><span>
                                          An Award Winning <br />Business Plan Template</span></h2>
                                    <div className="text-center">
                                          <h5 className="newsletter-subline">Enter Email below to unlock</h5>
                                    </div>
                                    <div className="px-3">
                                          <input className="form-control" type="email" value={email} placeholder="Enter Email ... " id="email" name="email" onChange={eventhandler} />
                                          <div className="text-center">
                                                {emailErr && (<span className="text-danger small d-block"> * {emailErr} </span>)}
                                          </div>
                                    </div>
                                    <div className="text-center">
                                          {/* <a href={imageurl.newsletterPDF} download="Newsletter"> */}
                                                <Button className="btn-newsletter" onClick={submitForm}>
                                                      GIVE ME MY TEMPLATE
                                                      <Icon icon="bi:arrow-right-circle" className="ms-2" color="white" />
                                                </Button>
                                          {/* </a> */}
                                    </div>
                              </Modal.Body>
                        </Modal>
                  }
            </>
      )
}

const mapDispatchToProps = dispatch => ({
      newsletter: payload => dispatch(newsletter(payload)),
})
export default connect(null, mapDispatchToProps)(withRouter(Newsletter));