import React, { useState } from "react";
import { Container, Navbar, Accordion, Nav } from "react-bootstrap";

import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import imageurl from "../common/images";
import Register from "./Register";

export const MobileHeader = () => {
  const [open, setOpen] = useState(false);
  const handleShow = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };
  const [show, setShow] = useState(false);
  window.addEventListener("scroll", changeColor);
  return (<>
    <Register show={open} onClose={handleClose} />
    <div className="mobile_header">
      <Navbar
        className={color ? "header header_bg" : "header header-transparent policy-mob"}
        expand="lg"
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img src={color ? imageurl.logo : imageurl.logo2} alt="" />
          </Navbar.Brand>
          <div className="partial">
            {/* <Link className="open_talk border-bottom-0 " to="/">
              <button className="talk_btn d-block w-100">
                <span>register</span>{" "}
                <Icon icon="bi:arrow-right-circle" color="white" />
              </button>
            </Link> */}
            {/* <div className="open_talk border-bottom-0 me-3">
              <button className="talk_btn d-block w-100 px-3" type="button" onClick={handleShow}>
                <span>Register</span>{" "}
                <Icon icon="bi:arrow-right-circle" color="white" />
              </button>
            </div> */}
            <span onClick={() => setShow(true)}><img src={imageurl.mobileham} alt="" /></span>
          </div>


          <div className={show ? "menu_box show_one" : " menu_box show_two"} >
            <div style={{ textAlign: "right", marginBottom: "12px" }}>
              <span onClick={() => setShow(false)}><Icon icon="ep:close" color="white" width="30" height="30" vFlip={true} /></span>
            </div>
            <Nav.Link className="mb-0" as={Link} to="#aboutBootcamp" onClick={() => setShow(false)}>About Us</Nav.Link>
            <Nav.Link as={Link} to="/" onClick={() => setShow(false)}>Our Process</Nav.Link>
            <Nav.Link as={Link} to="/" onClick={() => setShow(false)}>Samples</Nav.Link>
            <Nav.Link as={Link} to="/" onClick={() => setShow(false)}>Packages</Nav.Link>
            <Nav.Link as={Link} to="" onClick={() => setShow(false)}>What’s Included</Nav.Link>
            <Nav.Link as={Link} to="#bootcampReview" onClick={() => setShow(false)}>Reviews</Nav.Link>
            <div className="open_gym">
              <button type="button" className="talk_btn d-block w-100" onClick={handleShow}>
                <span>Register</span>{" "}
                <Icon icon="bi:arrow-right-circle" color="white" />
              </button>
              <Link className="open_talk border-bottom-0" to="/">
              </Link>
            </div>
          </div>
        </Container>
      </Navbar>
    </div>
  </>
  );
};
