const base_url = "https://convrtx.com/convertx_app/api"

// const base_url = "http://192.168.0.26:8080/api"

export const getCountries = base_url + "/get-countries"
export const pitchdeckSubmit = base_url + "/store-leads"
export const sendOtpApi = base_url + "/send-otp"
export const verifyOtpApi = base_url + "/verify-otp"
export const leadCount = base_url + "/lead-counter"
export const addnewsletter = base_url + "/addNewsLatter"
export const bootcampSubmit = base_url + "/bootcamp/signup"
export const getNewsPrList = base_url + "/news-pr"
export const getLatestPodcast = base_url + "/latest-podcast"
export const getInstagram = base_url + "/see-instagrm"
export const getTiktok = base_url + "/see-tiktok"
export const getYoutube = base_url + "/see-youtube"
export const getGuideList = base_url + "/guides"
export const getAllPodcast = base_url + "/podcast-list"
export const getChapterDetail = base_url + "/chapter-details"
export const getpodcast = base_url + "/mostviewed-podcast"
export const getInfluencer = base_url + "/influencer"
export const getGuideChapter = base_url + "/guides-chapter"
export const getpodcastDetail = base_url + "/find-podcast"
export const influencerCount = base_url + "/influencer-click"
export const totalWatchTime = base_url + "/total-watchtime"
export const totalListener = base_url + "/total-listeners"
