import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Container, Modal, NavLink, ProgressBar, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getHomePageData } from "../../store/actions/blogAction";
import { getPartners } from "../../store/actions/partnershipAction";
import imageurl from "../common/images";
import { Features } from "../Landingpage/Features";
import { Footer } from "../Landingpage/Footer";
import { Partners } from "../Landingpage/Partners";
import { Header } from "./Header";
import { MobileHeader } from "./MobileHeader";
import Register from "./Register";
import { Review } from "./Review";


const Bootcamp = (props) => { //getPartners, partnerships, currentPage, per_page, total_record
      useEffect(() => {
            props.getPartners(1);
      }, [getPartners]);

      const [show, setShow] = useState(false);
      const handleShow = () => setShow(true);
      const handleClose = () => setShow(false);

      const [more, setMore] = useState(false);
      const readMore = () => setMore(true);
      const readMoreClose = () => setMore(false);
      return (
            <>
                  <Header />
                  <MobileHeader />
                  <div className="hero_banner business_packages bootcamp_slider position-relative" >
                        <Container className="position-relative">
                              <Row>
                                    <Col lg={12}>
                                          <div className="ser_vi " style={{ justifyContent: "center" }}>
                                                <div className="mb-5">
                                                      <h5 className="bootcamp-heading">
                                                            <p className=" mb-0 d-inline">
                                                                  <span className="bootcamp-head-deco position-relative">Built for thinkers,</span> <br />
                                                                  Accelerated by <span className="convrtx-ico position-relative"> ConvrtX</span>
                                                            </p>
                                                      </h5>
                                                      <p className="title-para ">
                                                            ConvrtX is the global leader in Startup App Development.<br className="d-none d-sm-block" /> Over 10,000 startups worked with to-date. $4+ Billion in<br className="d-none d-sm-block" /> capital raised and counting.
                                                      </p>
                                                      <div className="deck_btns">
                                                            <button className="talk_btn btn-register" onClick={handleShow}>
                                                                  <span className="pe-2">Register&nbsp;for&nbsp;Bootcamp</span><Icon icon="bi:arrow-right-circle" color="white" />
                                                            </button>
                                                      </div>
                                                </div>
                                          </div>
                                    </Col>
                              </Row>
                              <img src={imageurl.bootcamp_slider1} className="img-fluid bootcamp_slider1" alt="image1" />
                              <span className="bootcamp-arrow"></span>
                        </Container>
                        <img src={imageurl.bootcamp_slider2} className="img-fluid bootcamp_slider2" alt="image1" />
                  </div>

                  {/* Begin:: About bootcamp  */}
                  <section className="container-fluid position-relative bootcamp-abtSec overflow-hidden px-0" id="aboutBootcamp">
                        <div className="ser_vi bookacal text-center mb-5">
                              <h5 className="fw-bold bootAbout-deco position-relative">About the  <span> Bootcamp</span></h5>
                        </div>
                        <div className="pt-lg-4">
                              <div className="row">
                                    <div className="col-md-5">
                                          <img src={imageurl.bootcamp_about} className="img-fluid about-img" alt="About us img" />
                                    </div>
                                    <div className="col-md-7">
                                          <ul className="about-bootcamp_list">
                                                <li>
                                                      <img src={imageurl.yellow_clock} className="img-fluid" alt="Clock" />
                                                      <div>
                                                            <h4>A global series of programs</h4>
                                                            <p>Each accelerator features a cohort of 10-15 top startups that scome together to tackle specific technical challenges that can help grow their businesses through a mix of remote and in-person, 1-to-1, group learning sessions, and sprint projects.</p>
                                                      </div>
                                                </li>
                                                <li>
                                                      <img src={imageurl.green_lock} className="img-fluid" alt="lock" />
                                                      <div>
                                                            <h4>Tailored, expert help</h4>
                                                            <p>Founders outline the top technical challenges for their startup, and are then paired with relevant experts from both Google and the industry to solve those challenges and grow their business.</p>
                                                      </div>
                                                </li>
                                                <li>
                                                      <img src={imageurl.purpul_headphone} className="img-fluid" alt="Headphone" />
                                                      <div>
                                                            <h4>Specialist deep dives</h4>
                                                            <p>In addition to mentorship and technical project support, Google for Startups accelerators include deep dives and workshops focused on product design, customer acquisition, and leadership development for founders.</p>
                                                      </div>
                                                </li>
                                          </ul>
                                    </div>
                              </div>
                              <div className="text-center mt-4">
                                    <button className="talk_btn btn-register" onClick={handleShow}>
                                          <span className="pe-2">Register for Bootcamp</span>
                                          <Icon icon="bi:arrow-right-circle" color="white" />
                                    </button>
                              </div>
                        </div>
                  </section>
                  {/* End:: About bootcamp  */}

                  <div className="bootcamp-partners">
                        <Partners
                              getPartners={props.getPartners}
                              partnerships={props.partnerships}
                              currentPage={props.currentPage}
                              per_page={props.per_page}
                              total_record={props.total_record}
                        />
                  </div>

                  {/* Begin:: Key benifits */}
                  <Container fluid className="keyBenefit-sec">
                        <Container>
                              <div className="ser_vi bookacal text-center mb-5">
                                    <h5 className="fw-bold position-relative"><span> Key benefits</span> of the <br className="d-none d-sm-block" />Bootcamp</h5>
                              </div>
                              <div className="row gx-lg-5">
                                    <div className="col-md-4 col-sm-6 key-card">
                                          <div className="key-icon"><Icon icon="bi-search" color="#FFFFFF" /></div>
                                          <h5>Equity-free support</h5>
                                          <p>Survey research is a quantitative method research method used for collecting data from a set of panel or respondents.</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6 key-card">
                                          <div className="key-icon"><Icon icon="bi-search" color="#FFFFFF" /></div>
                                          <h5>Business & Financial</h5>
                                          <p>Business finance refers to funds availed by business owners to meet their needs that include commencing a business.</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6 key-card">
                                          <div className="key-icon"><Icon icon="bi-search" color="#FFFFFF" /></div>
                                          <h5>Consultation Service</h5>
                                          <p>Consulting Services means the provision of expertise or strategic advice that is the presented for consideration.</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6 key-card">
                                          <div className="key-icon"><Icon icon="bi-search" color="#FFFFFF" /></div>
                                          <h5>Marketing & Sales</h5>
                                          <p>In the simplest of terms, marketing is the building awareness of your organization and brand to potential customers.</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6 key-card">
                                          <div className="key-icon"><Icon icon="bi-search" color="#FFFFFF" /></div>
                                          <h5>Planning & Supply Chain</h5>
                                          <p>Supply chain planning is the process of accurately planning the journey of a term of process of coordinating assets.</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6 key-card">
                                          <div className="key-icon"><Icon icon="bi-search" color="#FFFFFF" /></div>
                                          <h5>Product & UX</h5>
                                          <p>Essentially, the UX designer designs the start-to-end experience & design teams use to create products.</p>
                                    </div>
                              </div>

                              <div className="text-center mt-4">
                                    <button className="talk_btn btn-register bg-orange" onClick={handleShow}>
                                          <span className="pe-2">Register for Bootcamp</span>
                                          <Icon icon="bi:arrow-right-circle" color="white" />
                                    </button>
                              </div>
                        </Container>
                  </Container>
                  {/* End:: Key benifits */}

                  <Features />
                  <div id="bootcampReview">
                        <Review />
                  </div>

                  {/* Begin:: Chapter outline */}
                  <Container fluid className="chaper-outline">
                        <Container>
                              <div className="ser_vi bookacal text-center mb-5">
                                    <h5 className="fw-bold position-relative">Chapters <span>Outline</span></h5>
                              </div>
                              <ul className="chapter-container col-xl-10 mx-auto">
                                    <li className="chapter-list">
                                          <div className="chapter-week">
                                                <span>Week</span>
                                                <h2>1</h2>
                                          </div>
                                          <div className="chapter-info">
                                                <h3>UI/UX Design</h3>
                                                <div className="row align-items-center">
                                                      <div className="col-md-9"><p className="mb-0">Graphic design is the art and profession of using compositions to solve problems & illustration focuses on creative interpretation.</p></div>
                                                      <div className="col-md-3 text-end"><button type="button" className="btn btn-link p-0" onClick={readMore}>See more <Icon icon="bi-arrow-right" /></button></div>
                                                </div>
                                          </div>
                                    </li>
                                    <li className="chapter-list">
                                          <div className="chapter-week">
                                                <span>Week</span>
                                                <h2>2</h2>
                                          </div>
                                          <div className="chapter-info">
                                                <h3>Print Design & Illustration</h3>
                                                <div className="row align-items-center">
                                                      <div className="col-md-9"><p className="mb-0">Graphic design is the art and profession of using compositions to solve problems & illustration focuses on creative interpretation.</p></div>
                                                      <div className="col-md-3 text-end"><button type="button" className="btn btn-link p-0" onClick={readMore}>See more <Icon icon="bi-arrow-right" /></button></div>
                                                </div>
                                          </div>
                                    </li>
                                    <li className="chapter-list">
                                          <div className="chapter-week">
                                                <span>Week</span>
                                                <h2>3</h2>
                                          </div>
                                          <div className="chapter-info">
                                                <h3>Brand Design</h3>
                                                <div className="row align-items-center">
                                                      <div className="col-md-9"><p className="mb-0">Graphic design is the art and profession of using compositions to solve problems & illustration focuses on creative interpretation.</p></div>
                                                      <div className="col-md-3 text-end"><button type="button" className="btn btn-link p-0" onClick={readMore}>See more <Icon icon="bi-arrow-right" /></button></div>
                                                </div>
                                          </div>
                                    </li>
                                    <li className="chapter-list">
                                          <div className="chapter-week">
                                                <span>Week</span>
                                                <h2>4</h2>
                                          </div>
                                          <div className="chapter-info">
                                                <h3>UI/UX Design</h3>
                                                <div className="row align-items-center">
                                                      <div className="col-md-9"><p className="mb-0">Graphic design is the art and profession of using compositions to solve problems & illustration focuses on creative interpretation.</p></div>
                                                      <div className="col-md-3 text-end"><button type="button" className="btn btn-link p-0" onClick={readMore}>See more <Icon icon="bi-arrow-right" /></button></div>
                                                </div>
                                          </div>
                                    </li>
                                    <li className="chapter-list">
                                          <div className="chapter-week">
                                                <span>Week</span>
                                                <h2>5</h2>
                                          </div>
                                          <div className="chapter-info">
                                                <h3>UI/UX Design</h3>
                                                <div className="row align-items-center">
                                                      <div className="col-md-9"><p className="mb-0">Graphic design is the art and profession of using compositions to solve problems & illustration focuses on creative interpretation.</p></div>
                                                      <div className="col-md-3 text-end"><button type="button" className="btn btn-link p-0" onClick={readMore}>See more <Icon icon="bi-arrow-right" /></button></div>
                                                </div>
                                          </div>
                                    </li>
                                    <li className="chapter-list">
                                          <div className="chapter-week">
                                                <span>Week</span>
                                                <h2>6</h2>
                                          </div>
                                          <div className="chapter-info">
                                                <h3>UI/UX Design</h3>
                                                <div className="row align-items-center">
                                                      <div className="col-md-9"><p className="mb-0">Graphic design is the art and profession of using compositions to solve problems & illustration focuses on creative interpretation.</p></div>
                                                      <div className="col-md-3 text-end"><button type="button" className="btn btn-link p-0" onClick={readMore}>See more <Icon icon="bi-arrow-right" /></button></div>
                                                </div>
                                          </div>
                                    </li>
                                    <li className="chapter-list">
                                          <div className="chapter-week">
                                                <span>Week</span>
                                                <h2>7</h2>
                                          </div>
                                          <div className="chapter-info">
                                                <h3>UI/UX Design</h3>
                                                <div className="row align-items-center">
                                                      <div className="col-md-9"><p className="mb-0">Graphic design is the art and profession of using compositions to solve problems & illustration focuses on creative interpretation.</p></div>
                                                      <div className="col-md-3 text-end"><button type="button" className="btn btn-link p-0" onClick={readMore}>See more <Icon icon="bi-arrow-right" /></button></div>
                                                </div>
                                          </div>
                                    </li>
                                    <li className="chapter-list">
                                          <div className="chapter-week">
                                                <span>Week</span>
                                                <h2>8</h2>
                                          </div>
                                          <div className="chapter-info">
                                                <h3>UI/UX Design</h3>
                                                <div className="row align-items-center">
                                                      <div className="col-md-9"><p className="mb-0">Graphic design is the art and profession of using compositions to solve problems & illustration focuses on creative interpretation.</p></div>
                                                      <div className="col-md-3 text-end"><button type="button" className="btn btn-link p-0" onClick={readMore}>See more <Icon icon="bi-arrow-right" /></button></div>
                                                </div>
                                          </div>
                                    </li>
                              </ul>
                              <div className="text-center mt-4">
                                    <button className="talk_btn btn-register" onClick={handleShow}>
                                          <span className="pe-2">Register for Bootcamp</span>
                                          <Icon icon="bi:arrow-right-circle" color="white" />
                                    </button>
                              </div>
                        </Container>
                  </Container>
                  {/* End:: Chapter outline */}

                  {/* Begin :: Founders */}
                  <Container>
                        <div className="founder-block">
                              <img src={imageurl.founder_highlight} className="founder-higlight" />
                              <div className="row founder-row">
                                    <div className="col-md-4">
                                          <h2>Founder+</h2>
                                    </div>
                                    <div className="col-md-8 text-end d-flex justify-content-end position-relative">
                                          <div className="join-fees">
                                                <h2>$159</h2>
                                                <span>for 8 weeks</span>
                                          </div>
                                          <button className="talk_btn btn-register" onClick={handleShow}>
                                                <span className="pe-2">Register Now</span>
                                                <Icon icon="bi:arrow-right-circle" color="white" />
                                          </button>
                                          <img src={imageurl.founderArrow} className="founder-arrow" />
                                    </div>
                              </div>
                              <div className="mt-5">
                                    <h6 className="text-white mb-3">What’s included:</h6>
                                    <ul className="row whatsInclude mx-auto">
                                          <li className="col-md-6">Former VC/PE</li>
                                          <li className="col-md-6">Former VC/PE</li>
                                          <li className="col-md-6">Startups to Inc 5,000 and Fortune 50</li>
                                          <li className="col-md-6">Startups to Inc 5,000 and Fortune 50</li>
                                          <li className="col-md-6">Tier 1 VC grade Silicon Valley</li>
                                          <li className="col-md-6">Tier 1 VC grade Silicon Valley</li>
                                    </ul>
                              </div>
                              <div className="box">
                                    <div className="steps mt-5">
                                          <ProgressBar now={20} label={10} visuallyHidden />
                                    </div>
                              </div>
                              <div className="d-flex justify-content-between founder-footer">
                                    <label className="text-center d-block text-center mt-4"> <img src={imageurl.check} className="img-fluid me-2" alt="check ico" /> 23 <span>Students already registered to this bootcamp.</span></label>
                                    <label className="text-center d-block text-center mt-4"> <h6>7 Seats Left</h6></label>
                              </div>
                        </div>
                  </Container>
                  {/* End :: Founders */}

                  <Footer />
                  <Register show={show} onClose={handleClose} />

                  <Modal show={more} onHide={readMoreClose} size="lg" className="readmore-popup">
                        <Modal.Header closeButton className="border-bottom-0">
                              {/* <Modal.Title>Modal heading</Modal.Title> */}
                        </Modal.Header>
                        <Modal.Body>
                              <div className="chapter-list">
                                    <div className="chapter-week">
                                          <span>Week</span>
                                          <h2>8</h2>
                                    </div>
                                    <div className="chapter-info border-bottom-0">
                                          <h3>UI/UX Design</h3>
                                          <p >Graphic design is the art and profession of using compositions to solve problems & illustration focuses on creative interpretation.</p>
                                          <p>Graphic design is the art and profession of using compositions to solve problems & illustration focuses on creative interpretation.  What if you could place an advertisement in a space where your target audience spends more than five hours a day? And what if, at the end of that day, you had data about how your audience interacted with the ad or message?</p>

                                          <p>We’d like to re-introduce you to the humble email inbox.</p>

                                          <p>Adobe’s 2019 Email Usage Study found that Americans spend up to five hours a day checking personal or work emails.</p>

                                          <p>And 60% of respondents to Adobe’s survey said that they prefer to receive special offers by email—this is more than social media, direct mail, or other marketing channels.</p>

                                          <p>If your marketing strategy doesn’t include email marketing, you’re missing out on a chance to grow your revenue and build some serious customer loyalty and brand awareness.</p>

                                    </div>
                              </div>
                              <div className="text-center mb-5">
                                    <button className="talk_btn btn-register" onClick={handleShow}>
                                          <span className="pe-2">Register for Bootcamp</span>
                                          <Icon icon="bi:arrow-right-circle" color="white" />
                                    </button>
                              </div>
                        </Modal.Body>
                  </Modal>
            </>
      );
}

const mapStateToProps = ({
      partnership: { loading, partnerships, currentPage, per_page, total_record },
      // home: { blogs, events, portfolios },
}) => {
      return {
            partnerships,
            currentPage,
            per_page,
            total_record,
      };
};
const mapDispatchToProps = (dispatch) => {
      return {
            getPartners: (payload, page) => dispatch(getPartners(payload, page)),
            getHomePageData: () => dispatch(getHomePageData()),
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bootcamp);
