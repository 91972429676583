import { DEF, ERR_COUNTRIES, ERR_LEAD, GET_COUNTRIES, GET_LEAD, GET_LOADING, LOAD_LEAD, NEWSLETTER_ERROR, NEWSLETTER_SUBMIT, NEWSLETTER_SUCCESS, OTPVERIFY_ERROR, OTPVERIFY_SUCCESS, PITCHDECK_SUBMIT, PITCHDECK_SUCCESS, RESET_OTPVERIFY_SUBMIT, RESET_PITCHDECK_SUBMIT, RESET_SENDOTP_SUBMIT, SENDOTP_ERROR, SENDOTP_SUCCESS, SET_COUNTRIES } from "../common/types";

const initialState = {
  error: "",
  loading: false,
  countries: [],
};
const countriesReducer = (state = initialState, action) => {
  // console.log("action", action)
  switch (action.type) {
    case GET_COUNTRIES:
      return {
        ...state,
        loading: false,
        countries: action.payload,
      };
    case GET_LOADING:
      return {
        ...state,
        countries: [],
        loading: true,
      };
    case ERR_COUNTRIES:
      return {
        ...state,
        countries: [],
        loading: false,
        error: action.payload,
      };
    case DEF:
      return {
        ...state,
        countries: [],
        otp : "",
        success : false,

    };
    default:
      return state;
  }
};

const formState = {
  success: false,
  submit: false,
}

export const submitPitchdeckForm = (state = formState, action) => {
  switch (action.type) {
    case PITCHDECK_SUCCESS:
      return {
        ...state,
        lead_id : action.lead_id,
        statusCode : action.statusCode,
        success: true,
      };
    case PITCHDECK_SUBMIT:
      return {
        ...state,
        success: false,
        statusCode : action.statusCode,
        lead_id : "", 
        submitting: true,
      };
    case RESET_PITCHDECK_SUBMIT:
      return {
        ...state,
        success: false,
        statusCode : "",
        submitting: false,
      };
    default:
      return state;
  }
}
export const verifyOtpReducer = (state = formState, action) => {
  // console.log("action" , action)
  switch (action.type) {
    case OTPVERIFY_SUCCESS:
      // console.log( " sadasdsad", {
      //   ...state,
      //   success: true,
      //   statusCode : action.statusCode,
      // })
      return {
        ...state,
        success: true,
        statusCode : action.statusCode,
      };
    case OTPVERIFY_ERROR:
      return {
        ...state, 
        success: false,
        submitting: true,
        statusCode : action.statusCode,
      };
    case RESET_OTPVERIFY_SUBMIT:
      return {
        ...state,
        success: false,
        submitting: false,
        statusCode : "",
      };
  
    default:
      return state;
  }
}
export const sendOtpReducer = (state = formState, action) => {
  // console.log("action" , action)
  switch (action.type) {
    case SENDOTP_SUCCESS:
      // console.log( " sadasdsad", {
      //   ...state,
      //   success: true,
      //   otp : action.otp,
      //   statusCode : action.statusCode,

      // })
      return {
        ...state,
        success: true,
        statusCode : action.statusCode,
      };
    case SENDOTP_ERROR:
      return {
        ...state, 
        success: false,
        submitting: true,
        statusCode : action.statusCode,
      };
    case RESET_SENDOTP_SUBMIT:
      return {
        ...state,
        success: false,
        submitting: false,
        statusCode : action.statusCode,
      };
    default:
      return state;
  }
}

const leadState = {
  error: "",
  loading: false,
  leads: "",
}
export const leadCounterReducer = (state = leadState, action) => {
  switch (action.type) {
    case GET_LEAD:
      // console.log("payload", action.payload)
      return {
        ...state,
        loading: false,
        leads: action.payload,
      };
    case LOAD_LEAD:
      return {
        ...state,
        leads: "",
        loading: true,
      };
    case ERR_LEAD:
      return {
        ...state,
        leads: "",
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

const newsletterState = {
  success: false,
  submit: false,
}
export const newsletterReducer = (state = newsletterState, action) => {
  switch (action.type) {
    case NEWSLETTER_SUCCESS:
      return {
        ...state,
        success: true,
        statusCode : action.statusCode,
      };
    case NEWSLETTER_ERROR:
      return {
        ...state, 
        success: false,
        submitting: true,
        statusCode : action.statusCode,
      };
    case NEWSLETTER_SUBMIT:
      return {
        ...state,
        success: false,
        submitting: false,
        statusCode : action.statusCode,
      };
    default:
      return state;
  }
}
export default countriesReducer;
