import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import imageurl from "../common/images";

export const AppStartups = () => {
      return (
            <div className="pitch_startups" id="about">
                  <Container>
                        <div className="bookacall">
                              <Container>
                                    <Row>
                                          <Col lg={6}>
                                                <div className="ser_vi start_op mb-5">
                                                      <div>
                                                            <div className="mb-5">
                                                                  <div className="serv ps-0 serv2">
                                                                        <div className="ser_vi bookacal">
                                                                              <h5>
                                                                                    App development for <span>Startups</span>
                                                                              </h5>
                                                                              <p className="fw-semibold">
                                                                              ConvrtX is the global leader for startup app development.  Most even say we are the Largest Startup Ecosystem For Entrepreneurs. Whether you're refining an existing platform or just getting started, ConvrtX can help you achieve your next gen project.
                                                                              </p>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                          </Col>
                                          <Col lg={6}>
                                                <Row>
                                                      <Col lg={6}>
                                                            <div className="indian_box">
                                                                  <img src={imageurl.cloud} alt="" />
                                                                  <h5>3,500+</h5>
                                                                  <span>Global Clients</span>
                                                            </div>
                                                      </Col>
                                                      <Col lg={6}>
                                                            <div className="indian_box">
                                                                  <img src={imageurl.plans} alt="" />
                                                                  <h5>10,000+</h5>
                                                                  <span>Apps Developed</span>
                                                            </div>
                                                      </Col>
                                                </Row>
                                                <Row>
                                                      <Col lg={6}>
                                                            <div className="indian_box">
                                                                  <img src={imageurl.notepad} alt="" />
                                                                  <h5>$4B+</h5>
                                                                  <span>Billions in Capital Raised</span>
                                                            </div>
                                                      </Col>
                                                      <Col lg={6}>
                                                            <div className="indian_box">
                                                                  <img src={imageurl.communi} alt="" />
                                                                  <h5>Global</h5>
                                                                  <span>Representing Startups Everywhere</span>
                                                            </div>
                                                      </Col>
                                                </Row>
                                          </Col>
                                    </Row>
                              </Container>
                        </div>
                  </Container>
            </div>
      );
};
