

  // const baseurl = "http://localhost:3000";
  const baseurl = "https://convrtx.com";
  const imageurl = {
      blogbg: baseurl + "/images/blog_bg.png",
      whitham: baseurl + "/images/whit_ham.svg",
      logo2: baseurl + "/images/logo_white.png",
      default: baseurl + "/images/default.png",
      logo: baseurl + "/images/logo.png",
      footlogo: baseurl + "/images/footlogo.svg",
      about: baseurl + "/images/about.png",
      advise: baseurl + "/images/advisory.png",
      rec: baseurl + "/images/rec.svg",
      conlogo: baseurl + "/images/con_tran.svg",
      poplogo: baseurl + "/images/greenhome.png",
      advisey: baseurl + "/images/advise.svg",
      software: baseurl + "/images/software.svg",
      strategy: baseurl + "/images/strategy.svg",
      capital: baseurl + "/images/capital.svg",
      portfolioimg: baseurl + "/images/portfolio.png",
      brandlogo: baseurl + "/images/groupibran.svg",
      featuredlogo: baseurl + "/images/logoc.png",
      bannerbig: baseurl + "/images/banner_big.png",
      partners: baseurl + "/images/partners.png",
      ceo: baseurl + "/images/ceo.png",
      rocket: baseurl + "/images/rocket.svg",
      headphone: baseurl + "/images/headphone.svg",
      friends: baseurl + "/images/friends.svg",
      user: baseurl + "/images/user.png",
      upload: baseurl + "/images/upload.png",
      imageurl: baseurl + "/images/mono.png",
      partnership: baseurl + "/images/partnershipsp.png",
      josh: baseurl + "/images/josh.png",
      strategyAdvise: baseurl + "/images/advisory_stra.png",
      launch1: baseurl + "/images/launch1.png",
      launch2: baseurl + "/images/launch2.png",
      product1: baseurl + "/images/product1.png",
      product2: baseurl + "/images/product2.png",
      raising: baseurl + "/images/raising.png",
      raising2: baseurl + "/images/raising2.png",
      partnership2: baseurl + "/images/partnership.png",
      herosvg: baseurl + "/images/pg_hero.png",
      thankuthumb: baseurl + "/images/thumb-thanku.png",
      zoom: baseurl + "/images/fit_logo.png",
      sap: baseurl + "/images/swap.svg",
      staybusy: baseurl + "/images/staty.svg",
      picthrequire: baseurl + "/images/pitch_require.png",
      picthWin: baseurl + "/images/pitch_win.png",
      // picthWin: baseurl + "/images/pitch_win.png",
      tobusyowner: baseurl + "/images/mask_group.png",
      zoomowner: baseurl + "/images/owner_zomm.png",
      gold: baseurl + "/images/gold-ingot.svg",
      medal: baseurl + "/images/medal.svg",
      diamond: baseurl + "/images/diamond.svg",
      pitchhero: baseurl + "/images/pitch_hero.png",
      bookcall: baseurl + "/images/bookcall.png",
      businesssvg: baseurl + "/images/busi ness.png",
      notepad: baseurl + "/images/notepad.png",
      communi: baseurl + "/images/communication.png",
      cloud: baseurl + "/images/cloud_icon.png",
      plans: baseurl + "/images/plans.png",
      left: baseurl + "/images/arrow-fill-left.svg",
      right: baseurl + "/images/arrow-fill-right.svg",
      next: baseurl + "/images/next.svg",
      prev: baseurl + "/images/prev.svg",
      openadbanner: baseurl + "/images/openadbanner.svg",
      advisor1: baseurl + "/images/advisor1.png",
      advisor2: baseurl + "/images/advisor2.png",
      advisor3: baseurl + "/images/advisor3.png",
      accelarte: baseurl + "/images/accelarte.png",

      /*--ThreeHiddenPagees---*/
      business: baseurl + "/images/image9.png",
      newyork: baseurl + "/images/image10.png",
      jp: baseurl + "/images/image11.png",
      times: baseurl + "/images/image14.png",
      crunchbase: baseurl + "/images/image15.png",
      disprut: baseurl + "/images/image16.png",
      gulf: baseurl + "/images/image17.png",
      influencive: baseurl + "/images/image18.png",
      picthdesign: baseurl + "/images/pitch-design.png",
      businessdesign: baseurl + "/images/businessplanowner.png",
      ourBusness: baseurl + "/images/ourprocess.png",
      pitchben: baseurl + "/images/pitcyhbene.png",

      /*--Loan Types---*/
      bank: baseurl + "/images/bank.svg",
      moneycircle: baseurl + "/images/money-recive.svg",
      presentionchart: baseurl + "/images/presention-chart.svg",
      tubeline: baseurl + "/images/tubeline.svg",
      imagebusiness: baseurl + "/images/open_recieve.png",

      /*--Pdf Images---*/
      pdf1: baseurl + "/images/pdf1.png",
      pdf2: baseurl + "/images/pdf2.png",
      pdf3: baseurl + "/images/pdf3.png",
      pdf4: baseurl + "/images/pdf4.png",
      pdf5: baseurl + "/images/pdf5.png",
      pdf6: baseurl + "/images/pdf6.png",
      pdf7: baseurl + "/images/pdf7.png",
      pdf8: baseurl + "/images/pdf8.png",
      pdf9: baseurl + "/images/pdf9.png",
      pdf10: baseurl + "/images/pdf10.png",
      pdf11: baseurl + "/images/pdf11.png",
      pdf12: baseurl + "/images/pdf12.png",

      /*--trust icons--*/
      icon1: baseurl + "/images/icon1.svg",
      icon2: baseurl + "/images/icon2.svg",
      icon3: baseurl + "/images/icon3.svg",
      icon4: baseurl + "/images/icon4.svg",
      icon5: baseurl + "/images/icon5.svg",

      /*--packages icons--*/
      packageicon1: baseurl + "/images/shuttle.svg",
      packageicon2: baseurl + "/images/bacdiamond.svg",
      packageicon3: baseurl + "/images/premium.svg",
      /*--program icons--*/
      program1: baseurl + "/images/advi.svg",
      program2: baseurl + "/images/com.svg",
      program3: baseurl + "/images/eve.svg",

      /*--program icons--*/
      mobileham: baseurl + "/images/mobileham.svg",
      openlogo: baseurl + "/images/openlogo.png",

      part_one: baseurl + "/images/part_one.png",
      ourBusness2: baseurl + "/images/red.png",
      yahoo: baseurl + "/images/yahoo.png",
      forbes: baseurl + '/images/forbes.png',
      // loader: baseurl + "/images/loader.gif",

      // Policy images
      privacy_policy: baseurl + "/images/privacy-policy.png",
      terms_condition: baseurl + "/images/terms-condition.png",

      // failory images
      failoryImg: baseurl + "/images/failory.png",
      check: baseurl + "/images/check.png",

      privacyText: baseurl + "/images/privacy-policy.svg",
      TermsConditionText: baseurl + "/images/terms-condition.svg",

      // lock: baseurl + "/images/lock.png"

      newsletterPDF: baseurl + "/ConvrtX_BusinessPlan_Template.pdf",

      // Bootcamp slider
      bootcamp_slider1: baseurl + "/images/bootcamp-img1.png",
      bootcamp_slider2: baseurl + "/images/bootcamp-img2.png",
      bootcamp_about: baseurl + "/images/bootcamp-about.png",
      yellow_clock: baseurl + "/images/clock.png",
      green_lock: baseurl + "/images/lock.png",
      purpul_headphone: baseurl + "/images/headphone.png",
      founder_highlight: baseurl + "/images/highlight.png",
      founderArrow: baseurl + "/images/founder-arrow.png",
      badge: baseurl + "/images/badge.png",
      mediaPoster: baseurl + "/images/media-poster.png",

      // Podcast
      podcastDesk: baseurl + "/images/podcast-desk.png",
      podcastMob: baseurl + "/images/podcast-mob.png",
      thumbnail: baseurl + "/images/episode-thumbnail.png",
      playbtn: baseurl + "/images/playBtn.png",
      influencer: baseurl + "/images/influencer.png",

      tiktokPost: baseurl + "/images/tiktok-post.png",
      socialHero: baseurl + "/images/social-hero.png",
      guidesHero: baseurl + "/images/guides-hero.png",
      startupGuide: baseurl + "/images/startup-guide.png",
      chapterHero: baseurl + "/images/chapter-hero.png",
      startupHero: baseurl + "/images/startup-hero.png",

      b2b:baseurl + "/images/b2b.png",
      socialNetwork:baseurl + "/images/socialNetwork.png",
      bigData:baseurl + "/images/bigdata.png",
      web3:baseurl + "/images/web3.png",
      sass:baseurl + "/images/sass.png",
      healthTech:baseurl + "/images/health-tech.png",
      finTech:baseurl + "/images/finTech.png",

      b2b_ico:baseurl + "/images/b2b-ico.png",
      socialNetwork_ico:baseurl + "/images/socialNetwork-ico.png",
      bigData_ico:baseurl + "/images/bigdata-ico.png",
      web3_ico:baseurl + "/images/web3-ico.png",
      sass_ico:baseurl + "/images/sass-ico.png",
      healthTech_ico:baseurl + "/images/health-tech-ico.png",
      finTech_ico:baseurl + "/images/fintech-ico.png",

      mobileHero: baseurl+ "/images/mobile-hero.png",
      devHero: baseurl+ "/images/dev-hero.png",
      books: baseurl+ "/images/books.png",
      notFound: baseurl+ "/images/404.png",

      mediaPr_ico: baseurl+ "/images/play-cricle.svg",
      podcast_ico: baseurl+ "/images/podcast.svg",
      doc_ico: baseurl+ "/images/blog-ico.svg",
      social_ico: baseurl+ "/images/like.svg",

      longLeft: baseurl+ "/images/long-arrow-left.png",
      longRight: baseurl+ "/images/long-arrow-right.png",

      curve: baseurl+ "/images/curve.png",
  }
  export default imageurl;

