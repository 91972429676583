import { combineReducers } from "redux";
import { LATESTPODCAST_LIST } from "../common/types";
import authReducer, { loaderReducer, formReducer } from "./authReducer";
import blogReducer, { homeReducer } from "./blogReducer";
import bootcampReducer from "./bootcampReducer";
import { allpodcastReducer, GuideListReducer, influencerReducer, instagramReducer, latestPodcatReducer, newsPrReducer, podcstDetailReducer, tiktokReducer, youtubeReducer } from "./commonReducer";
import countriesReducer, { leadCounterReducer, sendOtpReducer, submitPitchdeckForm, verifyOtpReducer } from "./countriesReducer";
import eventReducer from "./eventReducer";
import partnershipReducer from "./partnershipReducer";
import portfolioReducer from "./portfolioReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  loader: loaderReducer,
  form: formReducer,
  blog: blogReducer,
  portfolio: portfolioReducer,
  event: eventReducer,
  partnership: partnershipReducer,
  home: homeReducer,
  countries: countriesReducer,
  pitchdeckSubmitForm: submitPitchdeckForm,
  verifyOtp: verifyOtpReducer,
  leads: leadCounterReducer,
  sendOtp: sendOtpReducer,
  bootcampSignup: bootcampReducer,
  newsPrList: newsPrReducer,
  instagram: instagramReducer,
  tiktok: tiktokReducer,
  youtube: youtubeReducer,
  guide: GuideListReducer,
  latestPodcastList: latestPodcatReducer,
  allPodcastList: allpodcastReducer,
  influencers: influencerReducer,
  podcastDetail: podcstDetailReducer,
});

export default rootReducer;
